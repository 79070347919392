import React from 'react';
import * as styles from 'modules/onboarding/styles/ReactionRoot.styles';
import CloseIcon from 'assets/icons/close.svg';
import EditIcon from 'assets/icons/Edit.svg';
import { useIntl } from 'gatsby-plugin-intl';

interface Props {
  title: string;
  content: string | null;
  id: string;
  onDelete: (id: string) => void;
  onEdit?: (id: string) => void;
  isFeatured?: boolean;
  isHeadquarters?: boolean;
}

export const ListItem: React.FC<Props> = ({
  title,
  content,
  id,
  onDelete,
  onEdit,
  isFeatured,
  isHeadquarters,
}) => {
  const intl = useIntl();

  return (
    <li css={styles.listItem}>
      {onEdit && (
        <EditIcon css={styles.listItemEdit} onClick={() => onEdit(id)} />
      )}
      <CloseIcon css={styles.listItemDelete} onClick={() => onDelete(id)} />
      <p css={styles.listItemHeading}>
        <label>
          {' '}
          {title}{' '}
          {isFeatured
            ? intl.formatMessage({
                id: 'onboarding.form.fields.project_list.featured',
              })
            : ''}
          {isHeadquarters
            ? intl.formatMessage({
                id: 'onboarding.form.fields.project_list.headquarters',
              })
            : ''}
        </label>
      </p>
      <p css={styles.listItemContent}>{content}</p>
    </li>
  );
};
