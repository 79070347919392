import React, { useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, TalentEducationInputs } from 'modules/app';
import { FormattedMessage } from 'react-intl';
import * as styles from 'modules/app/styles/GenericForm.styles';
import { useReset } from 'modules/onboarding';
import { useGhostTalentForm } from 'modules/ghostApplication/hooks';

interface Props {
  resetFormCounter?: () => void;
}

export const TalentEducationForm: React.FC<Props> = ({ resetFormCounter }) => {
  const { handleEducationDispatch } = useGhostTalentForm();

  const methods = useForm<EducationRequest>({
    defaultValues: {
      educationalInstitution: '',
      startYear: '',
      degree: null,
      fieldOfStudy: null,
      activities: null,
      associations: null,
      endYear: null,
    },
  });
  const { handleSubmit, reset, watch, clearErrors } = methods;
  const { resetValue, setReset } = useReset();
  const startYear = watch('startYear') || '0';
  const positionalRef = useRef<HTMLFormElement>(null);

  const onValid = async (data: EducationRequest) => {
    handleEducationDispatch(data);
    reset();
    setReset();
    resetFormCounter?.();
    clearErrors();
  };

  return (
    <div css={styles.root}>
      <FormProvider {...methods}>
        <form ref={positionalRef} onSubmit={handleSubmit(onValid)}>
          <TalentEducationInputs
            resetValue={resetValue}
            startYear={startYear}
          />

          <Button type="submit" css={styles.submitBtn}>
            <FormattedMessage id="onboarding.form.fields.education.save_education_button" />
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};
