import React from 'react';
import { grid } from '@prototyp/gatsby-plugin-gumball/utils';

import { ContentWrapper } from './ContentWrapper';
import { SerializedStyles } from '@emotion/react';

interface Props {
  heading: React.ReactNode;
  paragraph: React.ReactNode;
  customStyles?: SerializedStyles | SerializedStyles[];
}

export const InfoComponent: React.FC<Props> = ({
  heading,
  paragraph,
  customStyles,
}) => {
  return (
    <ContentWrapper customStyles={customStyles}>
      {heading}

      <div css={[grid.colSpan.c5, grid.colStart.c8]}>{paragraph}</div>
    </ContentWrapper>
  );
};
