import { css } from '@emotion/react';
import { breakpoints, grid } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  @media ${breakpoints.large} {
    ${grid.display};
    ${grid.template.cols12};
  }
`;

export const wrapper = css`
  @media ${breakpoints.large} {
    ${grid.display};
    ${grid.template.cols12};
    ${grid.colStart.c2};
    ${grid.colEnd.c12}
  }
`;
