import React, { useState } from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { FormattedMessage } from 'react-intl';
import { Heading } from 'modules/app';

import { Step } from './components/Step';
import {
  Basics,
  Certificates,
  Content,
  Experience,
  Languages,
  Portfolio,
} from './components';
import { ReactionBasics } from './components/reaction/ReactionBasics';
import { Expertise } from './components/Expertise';
import { ReactionExpertise } from './components/reaction/ReactionExpertise';
import { useAlternativeParams } from 'modules/application/hooks/useAlternativeParams';
import { ReactionExperience } from './components/reaction/ReactionExperience';
import { ReactionPortfolio } from './components/reaction/ReactionPortfolio';
import { Projects } from './components/Projects';
import { ReactionProjects } from './components/reaction/ReactionProjects';
import { ReactionCertificates } from './components/reaction/ReactionCertificates';
import { Education } from './components/Education';
import { ReactionEducation } from './components/reaction/ReactionEducation';
import { ReactionLanguages } from './components/reaction/ReactionLanguages';
import { NotFound } from 'modules/notFound';

export const GhostApplication: React.FC<RouteComponentProps> = () => {
  const pathData = useAlternativeParams('ghost');

  const [currentForm, setCurrentForm] = useState<HTMLFormElement>();
  const [isInRequest, setIsInRequest] = useState<boolean>(false);
  const [isRequestSuccessful, setIsRequestSuccessful] = useState(false);

  const onSubmit = () => {
    currentForm?.dispatchEvent(
      new Event('submit', {
        cancelable: true,
      }),
    );
  };

  return (
    <>
      {pathData.company && pathData.slug && (
        <Router>
          <Step
            reaction={
              <ReactionBasics
                pathData={pathData}
                path="basics"
                onSubmit={onSubmit}
                isRequestSuccess={isRequestSuccessful}
                setIsRequestSuccess={() => setIsRequestSuccessful(false)}
                isInRequest={isInRequest}
              />
            }
            path="basics"
            header={
              <>
                <Heading as="h1" size="fluidLarge">
                  <FormattedMessage id="publicApplication.ghostApplication.basics.header" />
                </Heading>
              </>
            }
            component={
              <Content>
                <Basics
                  setRequestSuccess={() => {
                    setIsRequestSuccessful(true);
                  }}
                  onInit={(form: HTMLFormElement) => setCurrentForm(form)}
                  onInRequest={setIsInRequest}
                />
              </Content>
            }
          />

          <Step
            reaction={
              <ReactionExpertise path="expertises" pathData={pathData} />
            }
            backTo="basics"
            path="expertises"
            header={
              <>
                <Heading as="h1" size="fluidLarge">
                  <FormattedMessage id="onboarding.headings.expertise.0" />
                </Heading>
              </>
            }
            component={<Expertise />}
          />

          <Step
            reaction={<ReactionExperience path="professional-experience" />}
            backTo="expertises"
            path="professional-experience"
            header={
              <>
                <Heading as="h1" size="fluidLarge">
                  <FormattedMessage id="onboarding.headings.experience_professional_experience.0" />
                </Heading>
                <Heading as="h1" size="fluidLarge">
                  <FormattedMessage id="onboarding.headings.experience_professional_experience.1" />
                </Heading>
              </>
            }
            component={<Experience />}
          />

          <Step
            reaction={<ReactionPortfolio path="portfolio" />}
            backTo="professional-experience"
            path="portfolio"
            header={
              <>
                <Heading as="h1" size="fluidLarge">
                  <FormattedMessage id="onboarding.headings.experience_portfolio_list.0" />
                </Heading>
                <Heading as="h1" size="fluidLarge">
                  <FormattedMessage id="onboarding.headings.experience_portfolio_list.1" />
                </Heading>
              </>
            }
            component={<Portfolio />}
          />

          <Step
            reaction={<ReactionProjects path="projects" />}
            backTo="portfolio"
            path="projects"
            header={
              <>
                <Heading as="h1" size="fluidLarge">
                  <FormattedMessage id="onboarding.headings.experience_project_list.0" />
                </Heading>
                <Heading as="h1" size="fluidLarge">
                  <FormattedMessage id="onboarding.headings.experience_project_list.1" />
                </Heading>
              </>
            }
            component={
              <Content>
                <Projects />
              </Content>
            }
          />

          <Step
            reaction={<ReactionCertificates path="certificates" />}
            backTo="projects"
            path="certificates"
            header={
              <>
                <Heading as="h1" size="fluidLarge">
                  <FormattedMessage id="onboarding.headings.education_certificates.0" />
                </Heading>

                <Heading as="h1" size="fluidLarge">
                  <FormattedMessage id="onboarding.headings.education_certificates.1" />
                </Heading>
              </>
            }
            component={
              <Content>
                <Certificates />
              </Content>
            }
          />

          <Step
            reaction={<ReactionEducation path="education" />}
            backTo="certificates"
            path="education"
            header={
              <>
                <Heading as="h1" size="fluidLarge">
                  <FormattedMessage id="onboarding.headings.education_education.0" />
                </Heading>

                <Heading as="h1" size="fluidLarge">
                  <FormattedMessage id="onboarding.headings.education_education.1" />
                </Heading>
              </>
            }
            component={
              <Content>
                <Education />
              </Content>
            }
          />

          <Step
            reaction={<ReactionLanguages path="languages" />}
            path="languages"
            backTo="education"
            header={
              <>
                <Heading as="h1" size="fluidLarge">
                  <FormattedMessage id="onboarding.headings.education_language.0" />
                </Heading>
                <Heading as="h1" size="fluidLarge">
                  <FormattedMessage id="onboarding.headings.education_language.1" />
                </Heading>
              </>
            }
            component={
              <Content>
                <Languages />
              </Content>
            }
          />

          <NotFound default type="contentOnlyCenter" />
        </Router>
      )}
    </>
  );
};
