import React from 'react';

import * as styles from 'modules/onboarding/styles/GenericForm.styles';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { TalentProjectsForm } from './form/TalentProjectsForm';

export const Projects: React.FC = () => {
  const ghostApplication = useSelector(
    (state: AppState) => state.applicationFlow.ghostApplication,
  );

  return (
    <div css={styles.root}>
      <TalentProjectsForm experienceData={ghostApplication.experiences} />
    </div>
  );
};
