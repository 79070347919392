import { applicationFlowActions } from 'modules/application';
import { AppState } from 'modules/redux-store';
import { useDispatch, useSelector } from 'react-redux';

export function useGhostTalentForm() {
  const dispatch = useDispatch();
  const ghostApplication = useSelector(
    (state: AppState) => state.applicationFlow.ghostApplication,
  );

  const handlePortfolioDispatch = (data: PortfolioData) => {
    const model: PortfolioData = {
      portfolioId: data.portfolioId,
      handle: data.portfolioId === 'other' ? null : data.url,
      url: data.portfolioId === 'other' ? data.url : null,
      description: data.description ? data.description : null,
    };

    ghostApplication.portfolios = [...ghostApplication.portfolios, model];
    dispatch(
      applicationFlowActions.updateGhostApplication({
        ghostApplication: { ...ghostApplication },
      }),
    );
  };

  const handleEducationDispatch = (data: EducationRequest) => {
    const model: GhostEducationRequest = {
      id: Date.now() + Math.random() + '',
      educationalInstitution: data.educationalInstitution,
      startYear: data.startYear.substring(0, 4),
      degree: data.degree || null,
      fieldOfStudy: data.fieldOfStudy || null,
      activities: data.activities || null,
      associations: data.associations || null,
      endYear: data.endYear ? data.endYear.substring(0, 4) : null,
    };

    ghostApplication.educations = [...ghostApplication.educations, model];
    dispatch(
      applicationFlowActions.updateGhostApplication({
        ghostApplication: { ...ghostApplication },
      }),
    );

    dispatch(
      applicationFlowActions.markEducation({
        isEducationDone: true,
      }),
    );
  };

  return {
    handlePortfolioDispatch,
    handleEducationDispatch,
  };
}
