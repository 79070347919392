import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Heading } from 'modules/app';
import { FormattedMessage } from 'react-intl';
import { applicationFlowActions } from 'modules/application';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';

import { ReactionWithList } from './ReactionWithList';
import { useAlternativeParams } from 'modules/application/hooks/useAlternativeParams';

export const ReactionLanguages: React.FC<RouteComponentProps> = () => {
  const pathData = useAlternativeParams('ghost');
  const ghostApplication = useSelector(
    (state: AppState) => state.applicationFlow.ghostApplication,
  );
  const dispatch = useDispatch();

  const removeLanguage = async (id: string) => {
    const idx = ghostApplication.languages.findIndex((exp) => exp.id === id);
    ghostApplication.languages.splice(idx, 1);

    dispatch(
      applicationFlowActions.updateGhostApplication({
        ghostApplication: { ...ghostApplication },
      }),
    );

    if (ghostApplication.languages.length === 0) {
      dispatch(
        applicationFlowActions.markLanguages({
          areLanguagesDone: false,
        }),
      );
    }
  };

  return (
    <ReactionWithList
      nextDisabled={Boolean(ghostApplication.languages.length === 0)}
      isLastStep={true}
      header={
        <Heading as="h1" size="fluidLarge">
          <FormattedMessage id="onboarding.dynamic_headings.languages.languages" />
        </Heading>
      }
      navigateTo={`/opening/application/${pathData.company}/${pathData.slug}/cover`}
      currentData={ghostApplication.languages.map((g) => {
        return {
          title: g.language,
          id: g.id,
          content: g.proficiency,
        };
      })}
      onDelete={removeLanguage}
    />
  );
};
