import React, { useEffect } from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { Overview } from './application/Overview';
import { CoverRoot } from './application/CoverRoot';
import { ApplyRoot } from './application/ApplyRoot';
import { Loading, useCsrfCookie } from 'modules/app';
import { GhostApplyRoot } from './application/GhostApplyRoot';
import { useApplication, useWizardRedirect } from './hooks';
import { AppLayout, MainContent } from 'modules/app/components';
import { HttpError } from 'modules/app/http';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils/margin';
import { isPublicOpening } from './misc';
import Seo from 'modules/app/components/Seo';
import { useIntl } from 'gatsby-plugin-intl';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';

interface Props extends RouteComponentProps {
  companyHandle?: string;
  openingSlug?: string;
}

export const Root: React.FC<Props> = ({ companyHandle, openingSlug }) => {
  const isGhostReady = useWizardRedirect();
  const { formatMessage } = useIntl();
  const { error, isLoading, publicOpening } = useSelector(
    (state: AppState) => state.openings,
  );
  const { getSingleOpening } = useApplication();

  const { isLoggedIn } = useCsrfCookie();

  useEffect(() => {
    if (!companyHandle || !openingSlug) return;
    getSingleOpening(companyHandle, openingSlug);
  }, []);

  return (
    <>
      <Seo title={formatMessage({ id: 'seoTitle.application' })} />

      <AppLayout isPublic>
        {isGhostReady && (
          <Loading
            isLoading={isLoading}
            customStyles={margin.top.xhuge}
            component={
              !error && publicOpening && isPublicOpening(publicOpening) ? (
                <Router>
                  {isLoggedIn() && (
                    <Overview path="overview" publicOpening={publicOpening} />
                  )}
                  <CoverRoot path="cover" publicOpening={publicOpening} />
                  {isLoggedIn() && (
                    <ApplyRoot path="apply" publicOpening={publicOpening} />
                  )}
                  {!isLoggedIn() && (
                    <GhostApplyRoot
                      path="apply"
                      publicOpening={publicOpening}
                    />
                  )}
                </Router>
              ) : (
                <MainContent>
                  <HttpError error={error} />
                </MainContent>
              )
            }
          />
        )}
      </AppLayout>
    </>
  );
};
