export function sortExperienceByDateDesc(
  listing: Experience[] | GhostExperience[],
) {
  const sortArray = [...listing];
  return sortArray.sort(
    (firstListing, secondListing) =>
      new Date(secondListing.employmentStartDate).getTime() -
      new Date(firstListing.employmentStartDate).getTime(),
  );
}
