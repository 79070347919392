import { FormattedMessage, Link, navigate } from 'gatsby-plugin-intl';
import { Button, Checkbox, HttpError, Loading } from 'modules/app';
import React, { useState } from 'react';

import * as styles from 'modules/application/styles/ActionButtonGroup.styles';
import ArrowRight from 'assets/icons/blackArrowLeft.svg';
import { useGdprResolver } from '../hooks/useGdprResolver';
import { ValidationError } from '../../app/components/ValidationError';
import { doNavigate } from 'modules/app/intl/doNavigate';

interface FormData {
  isTosAccepted: boolean;
  isPrivacyPolicyAccepted: boolean | undefined;
}

interface Props {
  openingSlug: string;
  companyHandle: string;
  onSubmit: (data: FormData) => void;
  submitError: ApiErrorData | undefined;
  isLoading: boolean;
  onGhostCancel?: () => void;
}

export const SubmitForm: React.FC<Props> = ({
  onSubmit,
  isLoading,
  companyHandle,
  submitError,
  openingSlug,
  onGhostCancel,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isProcessingChecked, setIsProcessingChecked] = useState(false);

  const {
    isLoading: isGdprLoading,
    gdpr,
    hasGdpr,
    gdprError,
  } = useGdprResolver(companyHandle);

  const onInternalSubmit = (data: FormData) => {
    if (!hasGdpr) {
      data.isPrivacyPolicyAccepted = undefined;
    }

    onSubmit(data);
  };

  const isDisabled = () => {
    if (hasGdpr) {
      return Boolean(!isChecked || !isProcessingChecked);
    }

    return !isChecked || isLoading;
  };

  return (
    <>
      <div css={styles.root}>
        <div>
          <Button
            icon={<ArrowRight />}
            buttonType="outline"
            onClick={() =>
              navigate(
                `/opening/application/${companyHandle}/${openingSlug}/cover`,
              )
            }
            type="button"
            size="medium"
          >
            <FormattedMessage id="publicApplication.backButton" />
          </Button>
        </div>

        <Loading
          isLoading={isGdprLoading}
          component={
            <>
              {!isGdprLoading && !Boolean(gdprError) && (
                <div>
                  {gdprError && gdprError.status !== 404 && (
                    <HttpError error={gdprError} />
                  )}

                  <div css={styles.wrapper}>
                    <Button
                      disabled={isDisabled()}
                      buttonType="secondary"
                      type="button"
                      onClick={() => {
                        if (!isDisabled()) {
                          if (hasGdpr) {
                            onInternalSubmit({
                              isTosAccepted: true,
                              isPrivacyPolicyAccepted: true,
                            });

                            return;
                          }

                          onInternalSubmit({
                            isTosAccepted: true,
                            isPrivacyPolicyAccepted: undefined,
                          });
                        }
                      }}
                      isLoading={isLoading}
                    >
                      <FormattedMessage id="publicApplication.apply.submitBtn" />
                    </Button>

                    <Button
                      buttonType="ghost"
                      onClick={
                        onGhostCancel
                          ? onGhostCancel
                          : () =>
                              doNavigate(
                                `/public-opening/${companyHandle}/${openingSlug}`,
                              )
                      }
                      type="button"
                    >
                      <FormattedMessage id="publicApplication.cancelStepButton" />
                    </Button>
                  </div>
                  <div css={styles.checkboxForm}>
                    {hasGdpr && gdpr && (
                      <Checkbox
                        isSmall={true}
                        name="isPrivacyPolicyAccepted"
                        checked={isProcessingChecked}
                        onChecked={setIsProcessingChecked}
                      >
                        <FormattedMessage
                          id="publicApplication.apply.tosLabel"
                          values={{
                            tos: (
                              <Link
                                to={`/company-privacy-policy/${companyHandle}`}
                              >
                                <FormattedMessage id="publicApplication.apply.privacyPolicy" />
                              </Link>
                            ),
                          }}
                        />
                      </Checkbox>
                    )}

                    <Checkbox
                      isSmall={true}
                      name="isTosAccepted"
                      checked={isChecked}
                      onChecked={setIsChecked}
                    >
                      <FormattedMessage
                        id="publicApplication.apply.policyLabel"
                        values={{
                          policy: (
                            <Link to="/terms-of-service">
                              <FormattedMessage id="publicApplication.apply.tos" />
                            </Link>
                          ),
                        }}
                      />
                    </Checkbox>
                  </div>
                </div>
              )}
            </>
          }
        />
      </div>
      <div css={styles.errorWrapper}>
        <ValidationError
          error={submitError}
          customStyles={styles.validationError}
          openingSlug={openingSlug}
          companyName={companyHandle}
          isGhostApply={Boolean(onGhostCancel)}
        />
      </div>
    </>
  );
};
