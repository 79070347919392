import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Heading, PortfolioIdEnum } from 'modules/app';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ReactionWithList } from './ReactionWithList';
import { AppState } from 'modules/redux-store';
import { applicationFlowActions } from 'modules/application';
import { useAlternativeParams } from 'modules/application/hooks/useAlternativeParams';

export const ReactionPortfolio: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const pathData = useAlternativeParams('ghost');

  const ghostApplication = useSelector(
    (state: AppState) => state.applicationFlow.ghostApplication,
  );

  const removeExperience = async (id: string) => {
    const idx = ghostApplication.portfolios.findIndex(
      (exp) => exp.portfolioId === id,
    );
    ghostApplication.portfolios.splice(idx, 1);

    dispatch(
      applicationFlowActions.updateGhostApplication({
        ghostApplication: { ...ghostApplication },
      }),
    );
  };

  return (
    <>
      <ReactionWithList
        header={
          <Heading as="h1" size="fluidLarge">
            <FormattedMessage id="onboarding.dynamic_headings.portfolio.portfolio_list" />
          </Heading>
        }
        navigateTo={`/opening/ghost/${pathData.company}/${pathData.slug}/projects`}
        currentData={ghostApplication.portfolios.map((g) => {
          return {
            title: PortfolioIdEnum[g.portfolioId],
            id: g.portfolioId,
            content: g.description || '',
          };
        })}
        onDelete={removeExperience}
      />
    </>
  );
};
