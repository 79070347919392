import React from 'react';

import * as styles from 'modules/onboarding/styles/GenericForm.styles';
import { BasicsForm } from './BasicsForm';

interface Props {
  setRequestSuccess: () => void;
  onInit: (form: HTMLFormElement) => void;
  onInRequest: (isInRequest: boolean) => void;
}

export const Basics: React.FC<Props> = ({
  onInit,
  onInRequest,
  setRequestSuccess,
}) => {
  return (
    <div css={styles.root}>
      <BasicsForm
        setRequestSuccess={setRequestSuccess}
        onInit={onInit}
        onInRequest={onInRequest}
      />
    </div>
  );
};
