import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useEffect, useState } from 'react';

export const useGdprResolver = (companyHandle: string) => {
  const [gdpr, setGdpr] = useState<Gdpr>();
  const [gdprError, setGdprError] = useState<ApiErrorData>();

  const http = useHttpClient('/api/v1/company');

  async function getGdpr() {
    try {
      const { data } = await http.get<Gdpr>(
        `${companyHandle}/settings/gdpr-settings`,
      );
      setGdpr(data);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setGdprError(e);
    }
  }

  useEffect(() => {
    getGdpr();
  }, [companyHandle]);

  return {
    isLoading: !Boolean(gdpr) && !Boolean(gdprError),
    hasGdpr: Boolean(gdpr) && gdprError?.status !== 404,
    gdpr: gdpr,
    gdprError: gdprError
      ? gdprError.status !== 404
        ? gdprError
        : undefined
      : undefined,
  };
};
