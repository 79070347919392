import React, { useEffect, useState } from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { GhostApplication } from './GhostApplication';
import { useCsrfCookie } from 'modules/app';
import { useAlternativeParams } from '../application/hooks';
import { navigate, useIntl } from 'gatsby-plugin-intl';
import Seo from 'modules/app/components/Seo';

export const Root: React.FC<RouteComponentProps> = () => {
  const { isLoggedIn } = useCsrfCookie();
  const [isReady, setIsReady] = useState(false);
  const pathData = useAlternativeParams('ghost');
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (isLoggedIn() && pathData.company && pathData.slug) {
      navigate(
        `/opening/application/overview/${pathData.company}/${pathData.slug}`,
      );

      return;
    }

    setIsReady(true);
  }, [isLoggedIn, pathData.company, pathData.slug]);

  return (
    <>
      <Seo title={formatMessage({ id: 'seoTitle.application' })} />

      {isReady && (
        <Router>
          <GhostApplication path="/:companyHandle/:openingSlug/*" />
        </Router>
      )}
    </>
  );
};
