import React, { useEffect, useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Button, TalentExperienceInputs } from 'modules/app';

import { useReset } from 'modules/onboarding';

import * as styles from 'modules/app/styles/GenericForm.styles';
import { useTalentExperienceForm } from 'modules/ghostApplication/hooks';

interface Props {
  resetFormCounter?: () => void;
}

export const TalentExperienceForm: React.FC<Props> = ({ resetFormCounter }) => {
  const {
    currentExperience,
    setCurrentExperience,
    handleExperienceEdit,
    handleExperienceAdd,
    handleExperienceEditReset,
  } = useTalentExperienceForm();

  const methods = useForm<ExperienceData>({
    defaultValues: {
      jobTitle: '',
      companyName: '',
      employmentStartDate: null,
      employmentEndDate: null,
      employmentType: null,
      workplace: [],
      jobDescription: null,
    },
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = methods;

  const { resetValue, setReset } = useReset();
  const formRef = useRef<HTMLFormElement>(null);

  const onSubmit = (data: ExperienceData) => {
    currentExperience
      ? handleExperienceEdit(data, currentExperience.id)
      : handleExperienceAdd(data);

    reset();
    setReset();
    setCurrentExperience(undefined);
    formRef?.current?.scrollIntoView({ behavior: 'smooth' });
    resetFormCounter?.();
  };

  useEffect(handleExperienceEditReset, []);

  useEffect(() => {
    if (Boolean(Object.keys(errors).length)) {
      formRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errors]);

  useEffect(() => {
    if (!currentExperience) return;

    setValue('workplace', currentExperience.workplace);
    setValue('employmentType', currentExperience.employmentType);
    setValue('jobDescription', currentExperience.jobDescription);
  }, [currentExperience]);

  return (
    <div css={styles.root}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          <TalentExperienceInputs
            resetValue={resetValue}
            currentExperience={currentExperience}
          />

          <Button type="submit" css={styles.submitBtn}>
            <FormattedMessage
              id={
                currentExperience
                  ? 'onboarding.form.fields.experience_employment.edit_experience'
                  : 'onboarding.form.fields.experience_employment.save_experience'
              }
            />
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};
