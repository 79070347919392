import React, { useState, useEffect } from 'react';
import * as styles from 'modules/onboarding/styles/Expertise.styles';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';

import { applicationFlowActions } from 'modules/application/redux';
import { TalentExpertiseForm } from './form/TalentExpertiseForm';
import { removeWhiteSpace } from 'modules/talents';

export const Expertise: React.FC = () => {
  const applicationFlow = useSelector(
    (state: AppState) => state.applicationFlow,
  );

  const [chosenExpertise, setChosenExpertise] = useState<string[]>(
    applicationFlow.ghostApplication.expertises,
  );

  const dispatch = useDispatch();
  const [totalExpertise, setTotalExpertise] = useState(0);

  const removeUserExpertise = (tagId: string) => {
    if (chosenExpertise) {
      const idx = chosenExpertise.findIndex((tag) => tag === tagId);

      chosenExpertise.splice(idx, 1);

      if (chosenExpertise.length === 0) {
        dispatch(
          applicationFlowActions.markExpertise({
            areExpertiseDone: false,
          }),
        );
      }

      setChosenExpertise([...chosenExpertise]);
    }
  };

  const effectFn = () => {
    if (chosenExpertise) {
      const ga: GhostApplication = {
        ...applicationFlow.ghostApplication,
        expertises: chosenExpertise,
      };

      if (!applicationFlow.areExpertiseDone && chosenExpertise.length !== 0) {
        dispatch(
          applicationFlowActions.markExpertise({
            areExpertiseDone: true,
          }),
        );
      }

      dispatch(
        applicationFlowActions.updateGhostApplication({
          ghostApplication: ga,
        }),
      );

      setTotalExpertise(chosenExpertise.length);
    }
  };

  useEffect(effectFn, [chosenExpertise]);

  return (
    <>
      <div css={styles.root}>
        <TalentExpertiseForm
          setChosenExpertise={(tag) =>
            setChosenExpertise([...chosenExpertise, tag])
          }
          totalExpertise={totalExpertise}
          setTotalExpertise={setTotalExpertise}
        />

        <div css={styles.chosen}>
          {chosenExpertise && chosenExpertise.length !== 0 && (
            <p css={styles.label}>
              <FormattedMessage id="onboarding.form.fields.expertise.your_chosen_expertise" />
            </p>
          )}

          <div css={styles.chosenExpertise}>
            <ul css={styles.chosenList}>
              {chosenExpertise &&
                chosenExpertise.map((expertise: string, idx) => (
                  <li css={styles.chosenListItem} key={idx}>
                    <p css={styles.chosenText}>
                      #{removeWhiteSpace(expertise)}
                    </p>
                    <button
                      onClick={() => removeUserExpertise(expertise)}
                      css={styles.chosenRemove}
                    >
                      <div css={styles.minus} />
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
