import React from 'react';
import { LabelTag, Heading, Avatar } from 'modules/app';
import { FormattedMessage } from 'react-intl';

import * as styles from 'modules/application/styles/Heading.styles';
import { ContentWrapper } from './ContentWrapper';

interface Props {
  title: string;
  companyName: string;
  companyPicture: Picture | null;
}
export const ApplicationHeading: React.FC<Props> = ({
  title,
  companyName,
  companyPicture,
}) => {
  return (
    <ContentWrapper>
      <div css={styles.root}>
        <div css={styles.wrapper}>
          <LabelTag fontFamily="primary" size="small">
            <FormattedMessage id="publicApplication.applyingForLabel" />
          </LabelTag>

          <Heading as="h1" size="xxLarge">
            {title}
          </Heading>
        </div>

        <div css={styles.avatarContainer}>
          <Avatar
            name={companyName}
            isShadow={false}
            imagePath={companyPicture?.small || undefined}
          />
          <LabelTag fontFamily="primary" size="small">
            {companyName}
          </LabelTag>
        </div>
      </div>
    </ContentWrapper>
  );
};
