import { scrollbar } from 'style/global.styles';
import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints, grid } from '@prototyp/gatsby-plugin-gumball/utils';
import * as btn from 'modules/app/styles/Button.styles';

export const heading = css`
  margin: ${spacing(120)} 0 ${spacing(60)} 0;

  @media ${breakpoints.large} {
    margin: calc(var(--unit) * 30) 0 calc(var(--unit) * 20) 0;
  }
`;

export const buttonGroup = css`
  width: 80%;
  margin: 0 auto;
`;

export const cvWrapper = css`
  max-height: 880px;
  overflow: auto;
  ${grid.colSpan.c12};

  ${scrollbar};

  @media ${breakpoints.large} {
    ${grid.colSpan.c10};
    ${grid.colStart.c2};
  }
`;

export const gdprLoading = css`
  margin-top: calc(var(--unit) * 10);
`;

export const fixBtn = css`
  ${btn.root};
  ${btn.primary};
  ${btn.medium};
  width: fit-content;
`;

export const error = css`
  ${grid.colSpan.c12};
`;
