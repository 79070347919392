import React, { useEffect } from 'react';
import { RouteComponentProps, useParams } from '@reach/router';
import { Heading, Pill, SuccessScreen } from 'modules/app';
import { FormattedMessage } from 'react-intl';
import { Link, useIntl } from 'gatsby-plugin-intl';
import Seo from 'modules/app/components/Seo';

import * as styles from 'modules/application/styles/FinalScreen.styles';
import { useApplication } from 'modules/application/hooks';

export const ConfirmApplication: React.FC<RouteComponentProps> = () => {
  const { formatMessage } = useIntl();
  const params = useParams();
  const { error, isLoading, confirmGhostApply } = useApplication();

  useEffect(() => {
    if (!params.token || typeof params.token !== 'string') return;

    confirmGhostApply(params.token);
  }, [params.token]);

  return (
    <>
      <Seo title={formatMessage({ id: 'seoTitle.applicationConfirm' })} />

      <SuccessScreen
        isLoading={isLoading}
        error={
          error && (
            <Pill
              type="error"
              title="http_errors.invalidGhostApplyTokenTitle"
              text="http_errors.invalidGhostApplyTokenText"
            />
          )
        }
        header={
          <Heading as="h1" size="fluidHuge">
            <FormattedMessage id="publicApplication.ghostApplication.applicationConfirmedHeader" />
          </Heading>
        }
        button={
          <Link to="/explore/openings" css={styles.findMoreBtn}>
            <FormattedMessage id="publicApplication.ghostApplication.findMoreOpeningsButton" />
          </Link>
        }
      />
    </>
  );
};
