import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Button, Heading, Logo, Paragraph } from 'modules/app';
import { FormattedMessage } from 'react-intl';
import { navigate } from 'gatsby-plugin-intl';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';

import * as styles from 'modules/onboarding/styles/ReactionRoot.styles';

interface Props extends RouteComponentProps {
  pathData: { company: string; slug: string };
}

export const ReactionExpertise: React.FC<Props> = ({ pathData }) => {
  const applicationFlow = useSelector(
    (state: AppState) => state.applicationFlow,
  );

  return (
    <div css={styles.root}>
      <div>
        <Logo />
        <div css={styles.headerGroup}>
          <Heading as="h1" size="fluidXxxLarge">
            <FormattedMessage id="onboarding.dynamic_headings.expertise.header_0" />
          </Heading>
          <Heading as="h1" size="fluidXxxLarge">
            <FormattedMessage id="onboarding.dynamic_headings.expertise.header_1" />
          </Heading>
        </div>

        <div css={styles.contentGroup}>
          <Paragraph size="fluidMedium">
            <FormattedMessage id="onboarding.dynamic_headings.expertise.content_0" />
          </Paragraph>

          <Paragraph size="fluidMedium">
            <FormattedMessage id="onboarding.dynamic_headings.expertise.content_1" />
          </Paragraph>
        </div>
      </div>

      <div css={styles.buttonGroupGhost}>
        <Button
          disabled={!applicationFlow.areExpertiseDone}
          buttonType="secondary"
          onClick={() => {
            navigate(
              `/opening/ghost/${pathData.company}/${pathData.slug}/professional-experience`,
            );
          }}
        >
          <FormattedMessage id="onboarding.form.next_step_up" />
        </Button>
      </div>
    </div>
  );
};
