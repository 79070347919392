import React from 'react';

import * as styles from '../styles/ContentWrapper.styles';

interface Props {
  customStyles?: SerializedStyles | SerializedStyles[];
}

export const ContentWrapper: React.FC<Props> = ({ children, customStyles }) => {
  return (
    <div css={styles.root}>
      <div css={[styles.wrapper, customStyles]}>{children}</div>
    </div>
  );
};
