import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const overviewStealth = css`
  padding: calc(var(--unit) * 30) 0 calc(var(--unit) * 20) 0;
`;

export const cover = css`
  padding: calc(var(--unit) * 30) 0 calc(var(--unit) * 20) 0;
`;

export const appSummary = css`
  padding: calc(var(--unit) * 30) 0 calc(var(--unit) * 10) 0;
  border-bottom: 1px solid hsl(var(--color-border-7));

  p {
    color: hsl(var(--color-grayscale-6));
  }

  @media ${breakpoints.mediumMax} {
    padding: 0;

    * {
      padding-bottom: ${spacing(32)};
    }
  }
`;

export const cvInfo = css`
  padding: calc(var(--unit) * 30) 0 calc(var(--unit) * 10) 0;
  margin-bottom: calc(var(--unit) * 10);

  p {
    color: hsl(var(--color-grayscale-6));
  }

  @media ${breakpoints.mediumMax} {
    padding: calc(var(--unit) * 15) 0 0 0;

    * {
      padding-bottom: ${spacing(32)};
    }
  }

  border-bottom: 1px solid hsl(var(--color-border-7));
`;

export const stealthMode = css`
  padding: calc(var(--unit) * 2) calc(var(--unit) * 4) calc(var(--unit) * 2)
    calc(var(--unit) * 4);
  background-color: hsl(var(--color-note-8));
  color: hsl(var(--color-text-1));

  margin-bottom: calc(var(--unit) * 10);
`;
