import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Heading } from 'modules/app';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ReactionWithList } from './ReactionWithList';
import { AppState } from 'modules/redux-store';
import { applicationFlowActions } from 'modules/application';
import { useAlternativeParams } from 'modules/application/hooks/useAlternativeParams';

export const ReactionCertificates: React.FC<RouteComponentProps> = () => {
  const ghostApplication = useSelector(
    (state: AppState) => state.applicationFlow.ghostApplication,
  );
  const pathData = useAlternativeParams('ghost');
  const dispatch = useDispatch();

  const removeCertificate = async (certificateId: string) => {
    const idx = ghostApplication.certificates.findIndex(
      (exp) => exp.id === certificateId,
    );
    ghostApplication.certificates.splice(idx, 1);

    dispatch(
      applicationFlowActions.updateGhostApplication({
        ghostApplication: { ...ghostApplication },
      }),
    );
  };

  return (
    <ReactionWithList
      header={
        <Heading as="h1" size="fluidLarge">
          <FormattedMessage id="onboarding.dynamic_headings.certificates.certificate_list" />
        </Heading>
      }
      navigateTo={`/opening/ghost/${pathData.company}/${pathData.slug}/education`}
      currentData={ghostApplication.certificates.map((g) => {
        return {
          title: g.name,
          id: g.id,
          content: g.issuingOrganization,
        };
      })}
      onDelete={removeCertificate}
    />
  );
};
