import React from 'react';

import * as styles from 'modules/onboarding/styles/GenericForm.styles';
import { TalentEducationForm } from './form/TalentEducationForm';

export const Education: React.FC = () => {
  return (
    <div css={styles.root}>
      <TalentEducationForm />
    </div>
  );
};
