import React from 'react';
import { Button } from 'modules/app';
import { FormattedMessage } from 'react-intl';

import ArrowRight from 'assets/icons/blackArrowLeft.svg';

import * as styles from 'modules/application/styles/ActionButtonGroup.styles';

interface Props {
  onNext?: () => void;
  onCancel: () => void;
  onBack?: () => void;
}

export const ActionButtonGroup: React.FC<Props> = ({
  onCancel,
  onNext,
  onBack,
}) => {
  return (
    <div css={styles.root}>
      {onBack && (
        <div>
          <Button
            icon={<ArrowRight />}
            buttonType="outline"
            onClick={onBack}
            type="button"
            size="medium"
          >
            <FormattedMessage id="publicApplication.backButton" />
          </Button>
        </div>
      )}

      <div css={styles.wrapper}>
        <Button buttonType="secondary" onClick={onNext} type="submit">
          <FormattedMessage id="publicApplication.nextStepButton" />
        </Button>

        <Button buttonType="ghost" onClick={onCancel} type="button">
          <FormattedMessage id="publicApplication.cancelStepButton" />
        </Button>
      </div>
    </div>
  );
};
