import React from 'react';
import { navigate, RouteComponentProps, useLocation } from '@reach/router';

import * as styles from 'modules/onboarding/styles/Step.styles';
import * as stepStyles from 'modules/onboarding/styles/StepRoot.styles';
import * as reactionStyles from 'modules/onboarding/styles/DynamicReaction.styles';
import { BackBtn } from 'modules/app/components/stepMechanism';
import { urlSplitter } from '../utils';

interface Props extends RouteComponentProps {
  header: React.ReactNode;
  component: React.ReactNode;
  reaction: React.ReactNode;
  backTo?: string;
}

export const Step: React.FC<Props> = ({
  header,
  component,
  reaction,
  backTo,
}) => {
  const { pathname } = useLocation();
  const showBackButton = !pathname.includes('basics');
  const newUrl = urlSplitter(pathname);

  return (
    <div css={stepStyles.root}>
      <div css={reactionStyles.root}>{reaction}</div>
      <div css={stepStyles.formsContent}>
        <div css={styles.root}>
          {showBackButton && (
            <BackBtn onBack={() => navigate(`${newUrl}/${backTo}`)} />
          )}
          <div>{header}</div>

          <div>{component}</div>
        </div>
      </div>
    </div>
  );
};
