import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  padding: ${spacing(100)} 0 ${spacing(52)} 0;
  display: flex;
  grid-column: span 12;

  @media ${breakpoints.mediumMax} {
    flex-direction: column;
  }

  @media ${breakpoints.large} {
    padding: calc(var(--unit) * 20) 0 calc(var(--unit) * 15) 0;
    justify-content: space-between;
  }

  & h1:first-of-type {
    margin-top: calc(var(--unit) * 5);
  }
`;

export const wrapper = css`
  max-width: 640px;

  > label {
    color: hsl(var(--color-textLight-7));
  }

  @media ${breakpoints.mediumMax} {
    order: 2;
  }
`;

export const avatarContainer = css`
  margin-top: calc(var(--unit) * 5);
  display: flex;
  align-items: center;

  label {
    margin-left: calc(var(--unit) * 3);
  }

  @media ${breakpoints.mediumMax} {
    order: 1;
    margin: 0 0 ${spacing(52)};
  }
`;
