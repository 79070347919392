import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { grid } from '@prototyp/gatsby-plugin-gumball/utils';
import { FormattedMessage } from 'react-intl';

import {
  CoverLetter,
  HeaderLayout,
  HttpError,
  MainContent,
  usePreviewContext,
  Heading,
  Paragraph,
  TalentPreviewBox,
  useCsrfCookie,
} from 'modules/app';
import { applicationFlowActions } from 'modules/application/redux';
import {
  Breadcrumbs,
  ApplicationHeading,
  ContentWrapper,
  InfoComponent,
  GhostBreadcrumbs,
} from 'modules/application/components';

import * as styles from 'modules/application/styles/Apply.styles';
import * as infoStyles from 'modules/application/styles/Info.styles';
import { SubmitForm } from '../components/SubmitForm';
import { doNavigate } from 'modules/app/intl/doNavigate';
import { useApplication } from '../hooks';

interface Props extends RouteComponentProps {
  publicOpening: PublicOpening;
}

export const GhostApplyRoot: React.FC<Props> = ({ publicOpening }) => {
  const dispatch = useDispatch();
  const { ghostApplication, coverLetter } = useSelector(
    (state: AppState) => state.applicationFlow,
  );
  const { isLoggedIn } = useCsrfCookie();
  const { isLoading, error, ghostUserApply } = useApplication();
  const { Provider } = usePreviewContext();

  function onSubmit(data: {
    isTosAccepted: boolean;
    isPrivacyPolicyAccepted: boolean | undefined;
  }) {
    ghostUserApply(publicOpening.companyHandle, publicOpening.slug, data);
  }

  return (
    <>
      <HeaderLayout>
        <ApplicationHeading
          title={publicOpening.title}
          companyName={publicOpening.company}
          companyPicture={publicOpening.companyIconPicture}
        />
        {isLoggedIn() && <Breadcrumbs state={['cover', 'apply']} />}
        {!isLoggedIn() && <GhostBreadcrumbs state={['cover', 'apply']} />}
      </HeaderLayout>

      <MainContent>
        {error && error.status !== 422 && <HttpError error={error} />}

        <ContentWrapper>
          <Heading
            css={[grid.colSpan.c12, styles.heading]}
            as="h1"
            size="fluidXxLarge"
          >
            <FormattedMessage id="publicApplication.apply.reviewHeader" />
          </Heading>
        </ContentWrapper>

        {coverLetter && (
          <>
            <InfoComponent
              customStyles={infoStyles.appSummary}
              heading={
                <Heading as="h2" size="fluidXLarge" css={[grid.colSpan.c6]}>
                  <FormattedMessage id="publicApplication.apply.stepTwoHeading" />
                </Heading>
              }
              paragraph={
                <Paragraph size="fluidBase" fontFamily="primary">
                  <FormattedMessage id="publicApplication.apply.stepTwoDesc" />
                </Paragraph>
              }
            />

            <ContentWrapper>
              <CoverLetter letter={coverLetter} />
            </ContentWrapper>
          </>
        )}

        <InfoComponent
          customStyles={infoStyles.cvInfo}
          heading={
            <Heading as="h2" size="fluidXLarge" css={[grid.colSpan.c6]}>
              <FormattedMessage id="publicApplication.apply.stepThreeHeading" />
            </Heading>
          }
          paragraph={
            <Paragraph size="fluidBase" fontFamily="primary">
              <FormattedMessage id="publicApplication.apply.stepThreeDesc" />
            </Paragraph>
          }
        />

        <Provider value={{ isPreview: true, isGhost: true }}>
          <div css={[grid.display, grid.template.cols12, grid.colSpan.c12]}>
            <div css={styles.cvWrapper}>
              <TalentPreviewBox
                previewData={ghostApplication}
                isOpeningCvPreview
              />
            </div>
          </div>
        </Provider>

        <ContentWrapper>
          <SubmitForm
            openingSlug={publicOpening.slug}
            companyHandle={publicOpening.companyHandle}
            isLoading={isLoading}
            onSubmit={onSubmit}
            submitError={error}
            onGhostCancel={() => {
              dispatch(applicationFlowActions.reset());

              doNavigate(
                `/public-opening/${publicOpening.companyHandle}/${publicOpening.slug}`,
              );
            }}
          />
        </ContentWrapper>
      </MainContent>
    </>
  );
};
