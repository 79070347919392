import { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';

export const useAlternativeParams = (type: 'ghost' | 'loggedIn') => {
  const loc = useLocation();
  const [pathData, setPathData] = useState({
    company: '',
    slug: '',
  });

  useEffect(() => {
    const split = loc.pathname.split('/');
    let company = split[type === 'ghost' ? 4 : 5];
    let slug = split[type === 'ghost' ? 5 : 6];

    if (slug === 'apply') {
      slug = split[5];
      company = split[4];
    }

    if (company && slug) {
      setPathData({
        company: company,
        slug: slug,
      });
    }
  }, [loc.pathname, type]);

  return pathData;
};
