import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  margin: ${spacing(120)} 0 ${spacing(80)} 0;

  @media ${breakpoints.medium} {
    margin: calc(var(--unit) * 15) 0 0 0;
  }

  & > p {
    margin: calc(var(--unit) * 5) 0 calc(var(--unit) * 5);
    color: hsl(var(--color-borderDark-5));
  }
`;

export const applySpacing = css`
  margin-top: calc(var(--unit) * 10);
`;
