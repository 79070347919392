import React, { useCallback, useEffect } from 'react';
import { StealthCv } from 'modules/applications/components';
import { HttpError, Loading } from 'modules/app';
import { grid } from '@prototyp/gatsby-plugin-gumball/utils/index';
import { SerializedStyles } from '@emotion/react';

import { ContentWrapper } from './ContentWrapper';

import * as styles from 'modules/application/styles/Preview.styles';
import * as baseStyles from 'modules/application/styles/Base.styles';
import { useGetPublicCompany } from 'modules/companyPreview';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { AnimatePresence, motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { useTalentPreview } from 'modules/talents';

interface Props {
  content?: React.ReactNode;
  customStyles?: SerializedStyles | SerializedStyles[];
  isInStealthMode?: boolean;
  companyHandle: string;
}

export const Preview: React.FC<Props> = ({
  content,
  customStyles,
  isInStealthMode = true,
  companyHandle,
}) => {
  const { getPublicCompany } = useGetPublicCompany();
  const { isLoading } = useSelector((state: AppState) => state.companyData);
  const { previewData, httpError, getApplicationPreview } = useTalentPreview();

  const effectFn = useCallback(() => {
    getApplicationPreview();
    getPublicCompany(companyHandle);
  }, [companyHandle, getPublicCompany]);

  useEffect(effectFn, []);

  return (
    <Loading
      isLoading={!previewData || isLoading}
      customStyles={baseStyles.loading}
      component={
        <AnimatePresence>
          {previewData && (
            <ContentWrapper customStyles={[styles.root, customStyles]}>
              <HttpError error={httpError} />
              <motion.div
                {...FADE_IN_MOVE_Y_REGULAR}
                css={[styles.root, grid.colSpan.c12]}
              >
                {content}
                {previewData && (
                  <StealthCv
                    application={previewData}
                    isStealth={isInStealthMode}
                  />
                )}
              </motion.div>
            </ContentWrapper>
          )}
        </AnimatePresence>
      }
    />
  );
};
