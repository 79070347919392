import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Button, HttpError, TalentPortfolioInputs } from 'modules/app';

import * as styles from 'modules/app/styles/GenericForm.styles';
import { useReset } from 'modules/onboarding';
import { useGhostTalentForm } from 'modules/ghostApplication/hooks';

interface Props {
  onNewListingItem?: (item: ListingData) => void;
  isProfile?: boolean;
  resetFormCounter?: () => void;
  currentPortfolio?: Portfolio;
  setCurrentPortfolios?: React.Dispatch<
    React.SetStateAction<Portfolio[] | undefined>
  >;
  currentPage?: number;
  setPageCount?: React.Dispatch<React.SetStateAction<number>>;
}

export const TalentPortfolioForm: React.FC<Props> = ({ resetFormCounter }) => {
  const { handlePortfolioDispatch } = useGhostTalentForm();

  const methods = useForm<PortfolioData>({
    defaultValues: {
      portfolioId: '',
      handle: null,
      url: null,
      description: null,
    },
  });
  const { handleSubmit, reset, clearErrors } = methods;

  const [formError, setFormError] = useState<ApiErrorData>();
  const { resetValue, setReset } = useReset();

  const onSubmit = async (data: PortfolioData) => {
    handlePortfolioDispatch(data);
    setFormError(undefined);
    reset();
    setReset();
    resetFormCounter?.();
    clearErrors();
  };

  return (
    <div css={styles.root}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <HttpError styles={styles.httpErrorFix} error={formError} />

          <TalentPortfolioInputs resetValue={resetValue} />

          <Button type="submit" css={styles.submitBtn}>
            <FormattedMessage id="onboarding.form.fields.portfolio.save_portfolio_submit" />
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};
