import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading, Sidebar } from 'modules/app';
import { TitlesWithLogo } from 'modules/app/components/blocks';
import { motion } from 'framer-motion';
import { FADE_IN } from 'style/animations.config';

type Step = [string[], string];

const registerStep: Record<string, Step> = {
  'step-one': [
    [
      'register.black_screens.header_register_screen_one.0',
      'register.black_screens.header_register_screen_one.1',
      'register.black_screens.header_register_screen_one.2',
    ],
    'register.black_screens.header_register_screen_one_footer',
  ],
  'step-two': [
    [
      'register.black_screens.header_register_screen_two.0',
      'register.black_screens.header_register_screen_two.1',
      'register.black_screens.header_register_screen_two.2',
    ],
    'register.black_screens.header_register_screen_two_footer',
  ],
  'step-three': [
    [
      'register.black_screens.header_register_screen_three.0',
      'register.black_screens.header_register_screen_three.1',
      'register.black_screens.header_register_screen_three.2',
    ],
    'register.black_screens.header_register_screen_three_footer',
  ],
  'step-four': [
    [
      'register.black_screens.header_register_screen_four.0',
      'register.black_screens.header_register_screen_four.1',
      'register.black_screens.header_register_screen_four.2',
    ],
    'register.black_screens.header_register_screen_four_footer',
  ],
  'final-step': [
    [
      'register.black_screens.header_register_screen_five.0',
      'register.black_screens.header_register_screen_five.1',
    ],
    'register.black_screens.header_register_screen_five_footer',
  ],
  'social-step-one': [
    [
      'register.black_screens.header_register_screen_two.0',
      'register.black_screens.header_register_screen_two.1',
      'register.black_screens.header_register_screen_two.2',
    ],
    'register.black_screens.header_register_screen_two_footer',
  ],
  'social-step-two': [
    [
      'register.black_screens.header_register_screen_three.0',
      'register.black_screens.header_register_screen_three.1',
      'register.black_screens.header_register_screen_three.2',
    ],
    'register.black_screens.header_register_screen_three_footer',
  ],
};

const loginStep: Record<string, Step> = {
  'step-one': [
    [
      'login.black_screens.header_login_screen_one.0',
      'login.black_screens.header_login_screen_one.1',
    ],
    'login.black_screens.login_page_footer',
  ],
  'step-two': [
    [
      'login.black_screens.header_login_screen_two.0',
      'login.black_screens.header_login_screen_two.1',
    ],
    'login.black_screens.2fa_page_footer',
  ],
};

const forgotPasswordStep: Record<string, Step> = {
  'step-one': [
    [
      'forgot_password.black_screens.forgot_password_header.0',
      'forgot_password.black_screens.forgot_password_header.1',
    ],
    '',
  ],
  'step-two': [
    [
      'forgot_password.black_screens.sent_password_reset_header.0',
      'forgot_password.black_screens.sent_password_reset_header.1',
    ],
    '',
  ],
};

const resetPasswordStep: Record<string, Step> = {
  'step-one': [
    [
      'reset_password.black_screens.reset_password_header.0',
      'reset_password.black_screens.reset_password_header.1',
    ],
    '',
  ],
  'step-two': [
    [
      'reset_password.black_screens.changed_password_header.0',
      'reset_password.black_screens.changed_password_header.1',
    ],
    '',
  ],
};

const data: Record<
  'register' | 'login' | 'forgotPassword' | 'resetPassword',
  | typeof registerStep
  | typeof loginStep
  | typeof forgotPasswordStep
  | typeof resetPasswordStep
> = {
  register: registerStep,
  login: loginStep,
  forgotPassword: forgotPasswordStep,
  resetPassword: resetPasswordStep,
};

interface Props {
  step: string;
  type: keyof typeof data;
  isRight?: boolean;
}

export const AuthBackground: React.FC<Props> = ({
  step,
  type,
  isRight = false,
}) => {
  const stepData = data[type];

  const title = (
    <motion.div
      key={`title-${step}`}
      {...FADE_IN}
      transition={{ duration: 0.8 }}
    >
      {stepData[step][0].map((stepId, i) => (
        <Heading key={i} as="h1" size="fluidHuge">
          <FormattedMessage id={stepId} />
        </Heading>
      ))}
    </motion.div>
  );

  const subtitle = (
    <motion.div
      key={`subtitle-${step}`}
      {...FADE_IN}
      transition={{ duration: 0.8 }}
    >
      {stepData[step][1] && <FormattedMessage id={stepData[step][1]} />}
    </motion.div>
  );

  return (
    <Sidebar>
      <TitlesWithLogo title={title} subtitle={subtitle} isRight={isRight} />
    </Sidebar>
  );
};
