import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Button, Heading, Logo } from 'modules/app';
import { FormattedMessage } from 'react-intl';

import * as styles from 'modules/onboarding/styles/ReactionRoot.styles';
import { Link, navigate } from 'gatsby-plugin-intl';

interface Props extends RouteComponentProps {
  onSubmit: () => void;
  isInRequest: boolean;
  isRequestSuccess: boolean;
  setIsRequestSuccess: () => void;
  pathData: { company: string; slug: string };
}

export const ReactionBasics: React.FC<Props> = ({
  onSubmit,
  isInRequest,
  isRequestSuccess,
  setIsRequestSuccess,
  pathData,
}) => {
  useEffect(() => {
    if (isRequestSuccess) {
      navigate(
        `/opening/ghost/${pathData.company}/${pathData.slug}/expertises`,
      );
      setIsRequestSuccess();

      return;
    }
  }, [isRequestSuccess, setIsRequestSuccess, pathData.company, pathData.slug]);

  return (
    <div css={styles.root}>
      <div>
        <Logo />

        <div css={styles.headerGroup}>
          <Heading as="h1" size="fluidXxxLarge">
            <FormattedMessage id="onboarding.dynamic_headings.basics.header_0" />
          </Heading>
          <Heading as="h1" size="fluidXxxLarge">
            <FormattedMessage id="onboarding.dynamic_headings.basics.header_1" />
          </Heading>
        </div>

        <div css={styles.subheaderGroup}>
          <Heading as="h2" size="fluidMedium">
            <FormattedMessage id="onboarding.dynamic_headings.basics.subheader_0" />
            <Link to="/support#stealth-mode">
              <FormattedMessage id="onboarding.dynamic_headings.basics.subheader_1" />
            </Link>
          </Heading>
        </div>
      </div>

      <div css={styles.buttonGroupGhost}>
        <Button
          disabled={isInRequest}
          buttonType="secondary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            onSubmit();
          }}
        >
          <FormattedMessage id="onboarding.form.next_step_up" />
        </Button>
      </div>
    </div>
  );
};
