import React from 'react';
import { navigate } from 'gatsby-plugin-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { RouteComponentProps } from '@reach/router';
import { HeaderLayout, MainContent, useCsrfCookie } from 'modules/app';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { ActionButtonGroup } from '../components/ActionButtonGroup';
import { CoverLetter } from '../components/CoverLetter';
import { applicationFlowActions } from '../redux';
import { AppState } from '../../redux-store';
import { CoverDescription } from '../components/CoverDescription';
import { ApplicationHeading } from '../components/ApplicationHeading';
import { GhostBreadcrumbs } from '../components/GhostBreadcrumbs';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { motion } from 'framer-motion';
import { ContentWrapper } from '../components';
import { doNavigate } from 'modules/app/intl/doNavigate';

import * as styles from 'modules/application/styles/CoverLetter.styles';
import { useFormGuard } from 'modules/app/hooks';

export interface CoverForm {
  coverLetter: string;
}

interface Props extends RouteComponentProps {
  publicOpening: PublicOpening;
}

export const CoverRoot: React.FC<Props> = ({ publicOpening }) => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useCsrfCookie();
  const coverLetter = useSelector(
    (state: AppState) => state.applicationFlow.coverLetter,
  );
  const methods = useForm<CoverForm>({
    defaultValues: {
      coverLetter: coverLetter,
    },
  });
  const nextStepPath = `/opening/application/${publicOpening.companyHandle}/${publicOpening.slug}/apply`;
  const { handleSubmit } = methods;
  useFormGuard(methods, nextStepPath);

  const onSubmit = (data: CoverForm) => {
    if (data.coverLetter) {
      dispatch(
        applicationFlowActions.addApplicationFlowData({
          coverLetter: data.coverLetter,
        }),
      );
    } else {
      dispatch(
        applicationFlowActions.addApplicationFlowData({
          coverLetter: '',
        }),
      );
    }

    navigate(nextStepPath);
  };

  const onBackClick = () => {
    isLoggedIn()
      ? navigate(
          `/opening/application/${publicOpening.companyHandle}/${publicOpening.slug}/overview`,
        )
      : navigate(
          `/opening/ghost/${publicOpening.companyHandle}/${publicOpening.slug}/languages`,
        );
  };

  return (
    <motion.div {...FADE_IN_MOVE_Y_REGULAR}>
      <HeaderLayout>
        <ApplicationHeading
          companyName={publicOpening.company}
          title={publicOpening.title}
          companyPicture={publicOpening.companyIconPicture}
        />

        {isLoggedIn() && <Breadcrumbs state={['cover']} />}
        {!isLoggedIn() && <GhostBreadcrumbs state={['cover']} />}
      </HeaderLayout>

      <MainContent>
        <ContentWrapper>
          <div css={styles.letterWrapper}>
            <CoverDescription />

            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <CoverLetter coverLetter={coverLetter} />

                <ActionButtonGroup
                  onBack={onBackClick}
                  onCancel={() => {
                    dispatch(applicationFlowActions.reset());

                    doNavigate(
                      `/public-opening/${publicOpening.companyHandle}/${publicOpening.slug}`,
                    );
                  }}
                />
              </form>
            </FormProvider>
          </div>
        </ContentWrapper>
      </MainContent>
    </motion.div>
  );
};
