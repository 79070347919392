import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';

import { useEffect, useState } from 'react';
import { navigate } from 'gatsby-plugin-intl';

import { useAlternativeParams } from 'modules/application/hooks/useAlternativeParams';
import { useCsrfCookie } from 'modules/app';

export const useWizardRedirect = () => {
  const { isLoggedIn } = useCsrfCookie();

  const applicationFlow = useSelector(
    (state: AppState) => state.applicationFlow,
  );
  const pathData = useAlternativeParams('loggedIn');
  const [isReady, setIsReady] = useState(false);

  const effectFn = () => {
    if (isLoggedIn()) {
      setIsReady(true);

      return;
    }

    if (pathData.company && pathData.slug && !isLoggedIn()) {
      if (!applicationFlow.areBasicsDone) {
        navigate(`/opening/ghost/${pathData.company}/${pathData.slug}/basics`);

        return;
      }

      if (!applicationFlow.areExpertiseDone) {
        navigate(
          `/opening/ghost/${pathData.company}/${pathData.slug}/expertises`,
        );

        return;
      }

      if (!applicationFlow.isEducationDone) {
        navigate(
          `/opening/ghost/${pathData.company}/${pathData.slug}/education`,
        );

        return;
      }

      if (!applicationFlow.areLanguagesDone) {
        navigate(
          `/opening/ghost/${pathData.company}/${pathData.slug}/languages`,
        );

        return;
      }

      setIsReady(true);
    }
  };

  useEffect(effectFn, [pathData.slug, pathData.company]);

  return isReady;
};
