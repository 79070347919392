import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'modules/app';
import WhiteImg from 'assets/icons/white_back.svg';
import DarkImg from 'assets/icons/back.svg';
import * as styles from 'modules/app/styles/BackBtn.styles';

interface Props {
  onBack: () => void;
  negative?: boolean;
}

const images = {
  dark: <DarkImg />,
  white: <WhiteImg />,
};

export const BackBtn: React.FC<Props> = ({ onBack, negative = false }) => {
  const type = negative ? 'white' : 'dark';

  return (
    <Button
      buttonType="ghost"
      css={styles.root}
      onClick={onBack}
      icon={images[type]}
    >
      <span css={styles[type]}>
        <FormattedMessage id="shared.page.back_btn" />
      </span>
    </Button>
  );
};
