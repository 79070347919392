import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { HeaderLayout, Heading, MainContent, Paragraph } from 'modules/app';
import { navigate } from 'gatsby-plugin-intl';
import { ActionButtonGroup } from '../components/ActionButtonGroup';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { Stealth } from '../components/Stealth';
import { Preview } from '../components/Preview';
import { ApplicationHeading } from '../components/ApplicationHeading';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ContentWrapper } from '../components/ContentWrapper';
import { AppState } from 'modules/redux-store';
import { applicationFlowActions } from '../redux';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { doNavigate } from 'modules/app/intl/doNavigate';

interface Props extends RouteComponentProps {
  publicOpening: PublicOpening;
}

export const Overview: React.FC<Props> = ({ publicOpening }) => {
  const dispatch = useDispatch();
  const privacyMode = useSelector(
    (state: AppState) => state.applicationFlow.privacyMode,
  );

  return (
    <motion.div {...FADE_IN_MOVE_Y_REGULAR}>
      <HeaderLayout>
        <ApplicationHeading
          title={publicOpening.title}
          companyName={publicOpening.company}
          companyPicture={publicOpening.companyIconPicture}
        />
        <Breadcrumbs />
      </HeaderLayout>

      <MainContent>
        <Stealth />
        <Preview
          companyHandle={publicOpening.companyHandle}
          isInStealthMode={privacyMode}
          content={
            <>
              <Heading as="h2" size="xLarge">
                <FormattedMessage
                  id={
                    privacyMode
                      ? 'publicApplication.preview.previewHeading'
                      : 'publicApplication.preview.publicModeHeading'
                  }
                />
              </Heading>

              <Paragraph size="base" fontFamily="primary">
                <FormattedMessage
                  id={
                    privacyMode
                      ? 'publicApplication.preview.description'
                      : 'publicApplication.preview.publicModeDescription'
                  }
                />
              </Paragraph>
            </>
          }
        />

        <ContentWrapper>
          <ActionButtonGroup
            onNext={() =>
              navigate(
                `/opening/application/${publicOpening.companyHandle}/${publicOpening.slug}/cover`,
              )
            }
            onCancel={() => {
              dispatch(applicationFlowActions.reset());

              doNavigate(
                `/public-opening/${publicOpening.companyHandle}/${publicOpening.slug}`,
              );
            }}
          />
        </ContentWrapper>
      </MainContent>
    </motion.div>
  );
};
