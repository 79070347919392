import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Button, TalentCertificateInputs } from 'modules/app';
import * as styles from 'modules/app/styles/GenericForm.styles';
import { useReset } from 'modules/onboarding';
import { applicationFlowActions } from 'modules/application';
import { AppState } from 'modules/redux-store';

interface Props {
  isProfile?: boolean;
  resetFormCounter?: () => void;
}

export const TalentCertificatesForm: React.FC<Props> = ({
  resetFormCounter,
}) => {
  const ghostApplication = useSelector(
    (state: AppState) => state.applicationFlow.ghostApplication,
  );
  const dispatch = useDispatch();

  const methods = useForm<CertificateData>({
    defaultValues: {
      name: '',
      issuingOrganization: '',
      issueDate: null,
      expirationDate: null,
      credentialId: null,
      credentialUrl: null,
    },
  });
  const { handleSubmit, reset } = methods;
  const { resetValue, setReset } = useReset();

  const onSubmit = async (data: CertificateData) => {
    const model: GhostCertificateRequest = {
      id: Date.now() + Math.random() + '',
      name: data.name,
      issuingOrganization: data.issuingOrganization,
      issueDate: data.issueDate
        ? `${data.issueDate.getFullYear()}-${
            data.issueDate.getMonth() + 1
          }-${data.issueDate.getDate()}`
        : '',
      expirationDate: data.expirationDate
        ? `${data.expirationDate.getFullYear()}-${
            data.expirationDate.getMonth() + 1
          }-${data.expirationDate.getDate()}`
        : null,
      credentialId: data.credentialId ? data.credentialId : null,
      credentialUrl: data.credentialUrl ? data.credentialUrl : null,
    };

    ghostApplication.certificates = [...ghostApplication.certificates, model];
    dispatch(
      applicationFlowActions.updateGhostApplication({
        ghostApplication: { ...ghostApplication },
      }),
    );

    reset();
    setReset();
    reset();
    setReset();

    resetFormCounter?.();
  };

  return (
    <div css={styles.root}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TalentCertificateInputs resetValue={resetValue} />

          <Button type="submit" css={styles.submitBtn}>
            <FormattedMessage id="onboarding.form.fields.certificates.save_certificate" />
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};
