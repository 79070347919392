import React, { useCallback, useEffect } from 'react';
import { RouteComponentProps, useParams } from '@reach/router';
import {
  AppLayout,
  HeaderLayout,
  Heading,
  HttpError,
  Loading,
  Paragraph,
  useCsrfCookie,
} from 'modules/app';
import { AppState } from 'modules/redux-store';
import { useGetPublicCompany } from 'modules/companyPreview/hooks';
import { grid } from '@prototyp/gatsby-plugin-gumball/utils';

import * as styles from 'modules/application/styles/FinalScreen.styles';
import { FormattedMessage } from 'react-intl';
import { applicationFlowActions } from '../redux';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useIntl } from 'gatsby-plugin-intl';
import Seo from 'modules/app/components/Seo';

export const FinalScreen: React.FC<RouteComponentProps> = () => {
  const { isLoggedIn } = useCsrfCookie();
  const dispatch = useDispatch();
  const params = useParams();
  const { getPublicCompany } = useGetPublicCompany();
  const {
    isLoading,
    error,
    publicCompany: company,
  } = useSelector((state: AppState) => state.companyData);
  const { formatMessage } = useIntl();

  const getCompany = useCallback(() => {
    getPublicCompany(params.companyHandle);
  }, [params.companyHandle, getPublicCompany]);

  useEffect(getCompany, [params.companyHandle]);

  useEffect(() => {
    dispatch(applicationFlowActions.reset());
  }, [dispatch]);

  return (
    <>
      <Seo title={formatMessage({ id: 'seoTitle.applicationFinal' })} />

      <AppLayout isPublic>
        <HeaderLayout>
          <Loading
            isLoading={isLoading && !company}
            component={
              <>
                {!isLoading && company && (
                  <div css={styles.gridContainer}>
                    <div
                      css={[grid.colSpan.c12, grid.colStart.c2, styles.root]}
                    >
                      <Heading css={grid.colSpan.c6} as="h1" size="fluidHuge">
                        <FormattedMessage id="publicApplication.finalScreen.heading1" />
                      </Heading>

                      <Heading css={grid.colSpan.c6} as="h1" size="fluidHuge">
                        <FormattedMessage id="publicApplication.finalScreen.heading2" />
                      </Heading>

                      <Paragraph
                        css={[grid.colSpan.c5]}
                        size="medium"
                        fontFamily="primary"
                      >
                        <FormattedMessage
                          id="publicApplication.finalScreen.applicationSuccess1"
                          values={{
                            companyName: company.name,
                          }}
                        />
                      </Paragraph>

                      <Paragraph
                        size="medium"
                        fontFamily="primary"
                        css={grid.colSpan.c5}
                      >
                        <FormattedMessage id="publicApplication.finalScreen.applicationSuccess2" />
                      </Paragraph>
                    </div>

                    <div css={styles.next}>
                      <Heading css={grid.colSpan.c5} as="h1" size="fluidXLarge">
                        <FormattedMessage id="publicApplication.finalScreen.whatHappensNext" />
                      </Heading>

                      <div css={[grid.colSpan.c5, grid.colEnd.c12]}>
                        <Paragraph size="medium" fontFamily="primary">
                          <FormattedMessage id="publicApplication.finalScreen.info1" />
                        </Paragraph>

                        <Paragraph size="medium" fontFamily="primary">
                          <FormattedMessage id="publicApplication.finalScreen.info2" />
                        </Paragraph>

                        <div css={[grid.colSpan.c12, styles.buttonGroup]}>
                          <Link
                            to={
                              isLoggedIn()
                                ? '/profile/talent/openings'
                                : '/explore/openings'
                            }
                            css={styles.findMoreBtn}
                          >
                            <FormattedMessage id="publicApplication.finalScreen.button1" />
                          </Link>

                          {isLoggedIn() && (
                            <Link
                              to="/profile/talent/overview/invitations"
                              css={styles.dashboardBtn}
                            >
                              <FormattedMessage id="publicApplication.finalScreen.button2" />
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            }
          />

          <HttpError error={error} />
        </HeaderLayout>
      </AppLayout>
    </>
  );
};
