import { css } from '@emotion/react';
import { breakpoints } from 'style/variables';
import { small } from './Button.styles';

export const white = css`
  color: white;
`;

export const dark = css`
  color: hsl(var(--color-text-1));
`;

export const root = css`
  display: none;

  @media ${breakpoints.large} {
    display: inline-flex;
    ${small};
    font-size: var(--font-size-base);
    text-align: left;
  }
`;
