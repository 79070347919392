import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Heading } from 'modules/app';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ReactionWithList } from './ReactionWithList';
import { AppState } from 'modules/redux-store';
import { applicationFlowActions } from 'modules/application';
import { useAlternativeParams } from 'modules/application/hooks/useAlternativeParams';
import { sortExperienceByDateDesc } from 'modules/profilePreview/utils';

export const ReactionExperience: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const pathData = useAlternativeParams('ghost');

  const ghostApplication = useSelector(
    (state: AppState) => state.applicationFlow.ghostApplication,
  );
  const sortedGhostExperiences = sortExperienceByDateDesc(
    ghostApplication.experiences,
  );

  const removeExperience = async (experienceId: string) => {
    const idx = ghostApplication.experiences.findIndex(
      (exp) => exp.id === experienceId,
    );
    ghostApplication.experiences.splice(idx, 1);

    dispatch(
      applicationFlowActions.updateGhostApplication({
        ghostApplication: { ...ghostApplication },
      }),
    );
  };

  const editExperience = (experienceId: string) => {
    const foundExperience = ghostApplication.experiences.find(
      (exp) => exp.id === experienceId,
    );

    dispatch(
      applicationFlowActions.editExperience({
        experience: foundExperience,
      }),
    );
  };

  return (
    <>
      <ReactionWithList
        header={
          <Heading as="h1" size="fluidLarge">
            <FormattedMessage id="onboarding.dynamic_headings.experience.experience_list" />
          </Heading>
        }
        navigateTo={`/opening/ghost/${pathData.company}/${pathData.slug}/portfolio`}
        currentData={sortedGhostExperiences.map((g) => {
          return {
            title: g.jobTitle,
            id: g.id,
            content: g.companyName,
          };
        })}
        onDelete={removeExperience}
        onEdit={editExperience}
      />
    </>
  );
};
