import { css } from '@emotion/react';

export const root = css`
  background-color: white;

  & > div > div:first-of-type {
    background-color: hsl(var(--color-grayscale-1));
  }
`;

export const letterWrapper = css`
  grid-column: span 12;
`;
