import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints, grid } from '@prototyp/gatsby-plugin-gumball/utils';
import * as btn from 'modules/app/styles/Button.styles';

export const root = css`
  & > h1:last-of-type {
    padding-bottom: calc(var(--unit) * 15);
  }
`;

export const gridContainer = css`
  padding: ${spacing(120)} 0;

  @media ${breakpoints.mediumMax} {
    max-width: 768px;
    padding: ${spacing(80)} 0;
  }

  @media ${breakpoints.large} {
    ${grid.display};
    ${grid.template.cols12};
    ${grid.colSpan.c12};
  }
`;

export const next = css`
  padding-top: calc(var(--unit) * 20);

  @media ${breakpoints.large} {
    ${grid.display};
    ${grid.template.cols12};
    ${grid.colSpan.c12};
    ${grid.colStart.c2};
  }

  & p:last-of-type {
    color: hsl(var(--color-textLight-7));

    margin-top: calc(var(--unit) * 4);
  }
`;

export const buttonGroup = css`
  margin-top: calc(var(--unit) * 10);
  display: grid;
  grid-gap: calc(var(--unit) * 4);

  @media ${breakpoints.mediumMax} {
    grid-template-columns: auto auto;
  }

  @media ${breakpoints.smallMax} {
    grid-template-columns: none;
  }

  @media ${breakpoints.xxLarge} {
    grid-template-columns: auto auto;
  }
`;

export const dashboardBtn = css`
  ${btn.root};
  ${btn.outline};
  color: hsl(var(--color-grayscale-1));
  border-color: hsl(var(--color-borderDark-5));

  &:hover {
    border-color: hsl(var(--color-borderLight-8));
  }
`;

export const findMoreBtn = css`
  ${btn.root};
  ${btn.secondary};
`;
