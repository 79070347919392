import React from 'react';
import { LabelTag, Paragraph } from 'modules/app';
import { FormattedMessage } from 'react-intl';

import * as styles from 'modules/application/styles/Breadcrumbs.styles';

import CheckIcon from 'assets/icons/check.svg';

interface Props {
  state?: string[];
}

export const GhostBreadcrumbs: React.FC<Props> = ({ state }) => {
  return (
    <div css={styles.wrapper}>
      <div css={styles.root}>
        <div css={state && state.includes('apply') ? styles.passed : undefined}>
          <LabelTag fontFamily="primary" size="base">
            {state && state.includes('apply') ? <CheckIcon /> : 1}
          </LabelTag>

          <Paragraph size="base" fontFamily="primary">
            <FormattedMessage id="publicApplication.breadcrumbs.ghostStep1" />
          </Paragraph>
        </div>

        <div css={styles.dottedSeparator} />

        <div>
          <LabelTag fontFamily="primary" size="base">
            2
          </LabelTag>

          <Paragraph size="base" fontFamily="primary">
            <FormattedMessage id="publicApplication.breadcrumbs.ghostStep2" />
          </Paragraph>
        </div>
      </div>
    </div>
  );
};
