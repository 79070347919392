import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useApplications } from 'modules/talentDashboard/hooks';
import { openingsActions } from 'modules/openings/redux';
import { AppState } from 'modules/redux-store';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby-plugin-intl';
import { useState } from 'react';

interface SubmitData {
  isTosAccepted: boolean;
  isPrivacyPolicyAccepted: boolean | undefined;
}

export const useApplication = () => {
  const dispatch = useDispatch();
  const { getActiveApplications } = useApplications();

  const { privacyMode, coverLetter, ghostApplication } = useSelector(
    (state: AppState) => state.applicationFlow,
  );
  const [error, setError] = useState<HttpError>();
  const [isLoading, setIsLoading] = useState(false);

  const http = useHttpClient('/api/v1/company');

  async function getSingleOpening(companyHandle: string, openingSlug: string) {
    if (!companyHandle || !openingSlug) return;

    dispatch(openingsActions.loading());
    try {
      const { data } = await http.get<PublicOpening>(
        `${companyHandle}/profile/openings/${openingSlug}`,
      );
      dispatch(openingsActions.addPublicOpening(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(openingsActions.error(e));
    }
  }

  async function userApply(
    companyHandle: string,
    openingSlug: string,
    info: SubmitData,
  ) {
    const model: LoggedInUserApply = {
      coverLetter: Boolean(coverLetter) ? coverLetter : null,
      privacyMode: privacyMode ? 'private' : 'public',
      isTosAccepted: info.isTosAccepted,
    };

    if (typeof info.isPrivacyPolicyAccepted !== 'undefined') {
      model.isPrivacyPolicyAccepted = info.isPrivacyPolicyAccepted;
    }

    setIsLoading(true);
    try {
      await http.post<LoggedInUserApply>(
        `/${companyHandle}/openings/${openingSlug}/apply`,
        model,
      );
      getActiveApplications();
      navigate(`/opening/finish/${companyHandle}`);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function ghostUserApply(
    companyHandle: string,
    openingSlug: string,
    info: SubmitData,
  ) {
    const model: GhostApplication = {
      ...ghostApplication,
      isTosAccepted: info.isTosAccepted,
      coverLetter: coverLetter ? coverLetter : null,
    };

    if (typeof info.isPrivacyPolicyAccepted !== 'undefined') {
      model.isPrivacyPolicyAccepted = info.isPrivacyPolicyAccepted;
    }

    setIsLoading(true);
    try {
      await http.post<GhostApplication>(
        `/${companyHandle}/openings/${openingSlug}/ghost-apply`,
        model,
      );
      navigate(`/opening/finish/${companyHandle}`);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function confirmGhostApply(token: string) {
    setIsLoading(true);
    try {
      await http.post(`/openings/ghost-confirm-apply/${token}`);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  return {
    error,
    isLoading,
    userApply,
    ghostUserApply,
    getSingleOpening,
    confirmGhostApply,
  };
};
