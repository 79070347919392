import { css } from '@emotion/react';
import { grid } from '@prototyp/gatsby-plugin-gumball/utils';

export const gridFix = css`
  & > div:first-of-type {
    ${grid.colSpan.c12}
  }
`;

export const acceptTerms = css`
  background-color: hsla(var(--color-textLight-2), 10%);
  padding: calc(var(--unit) * 5);
  border-radius: var(--border-radius-medium);
  margin-top: calc(var(--unit) * 10);
  margin-bottom: calc(var(--unit) * 15);

  a {
    text-decoration: underline;
    color: hsl(var(--color-text-1));
  }
`;

export const acceptTermsError = css`
  border: 1px solid hsl(var(--color-warning-6));
`;
