import { useToastify } from 'modules/app';
import { applicationFlowActions } from 'modules/application';
import { AppState } from 'modules/redux-store';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

export function useTalentExperienceForm() {
  const dispatch = useDispatch();
  const { toastSuccess } = useToastify();
  const [currentExperience, setCurrentExperience] = useState<
    GhostExperience | undefined
  >(undefined);
  const { ghostApplication, experienceToEdit } = useSelector(
    (state: AppState) => state.applicationFlow,
  );

  const normalizeExperienceData = (data: ExperienceData) => {
    return {
      jobTitle: data.jobTitle,
      companyName: data.companyName,
      employmentStartDate: data.employmentStartDate
        ? `${data.employmentStartDate.getFullYear()}-${
            data.employmentStartDate.getMonth() + 1
          }-01`
        : '',
      employmentEndDate: data.employmentEndDate
        ? `${data.employmentEndDate.getFullYear()}-${
            data.employmentEndDate.getMonth() + 1
          }-${data.employmentEndDate.getDate()}`
        : null,
      employmentType: data.employmentType === '' ? null : data.employmentType,
      workplace: data.workplace,
      jobDescription: data.jobDescription,
      id: Date.now() + Math.random() + '',
    };
  };

  const handleExperienceDispatch = (
    newExperience: GhostExperience,
    oldExperiences?: GhostExperience[],
  ) => {
    const experienceToSpread = oldExperiences || ghostApplication.experiences;
    ghostApplication.experiences = [...experienceToSpread, newExperience];

    dispatch(
      applicationFlowActions.updateGhostApplication({
        ghostApplication: { ...ghostApplication },
      }),
    );
  };

  const handleExperienceEdit = (data: ExperienceData, id: string) => {
    const newExperience = normalizeExperienceData(data);
    const oldExperiences = ghostApplication.experiences.filter(
      (experience) => experience.id !== id,
    );
    handleExperienceDispatch(newExperience, oldExperiences);
    toastSuccess(
      'onboarding.form.fields.experience_employment.edit_experience.success',
    );
  };

  const handleExperienceAdd = (data: ExperienceData) => {
    const newExperience = normalizeExperienceData(data);
    handleExperienceDispatch(newExperience);
  };

  const handleExperienceEditReset = () => {
    dispatch(applicationFlowActions.editExperience({ experience: undefined }));
    setCurrentExperience(undefined);
  };

  useEffect(() => {
    if (!experienceToEdit) return;
    setCurrentExperience(experienceToEdit);
  }, [experienceToEdit]);

  return {
    currentExperience,
    setCurrentExperience,
    handleExperienceAdd,
    handleExperienceEdit,
    handleExperienceEditReset,
  };
}
