import React from 'react';
import { Heading } from 'modules/app';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { applicationFlowActions } from 'modules/application';
import { useAlternativeParams } from 'modules/application/hooks/useAlternativeParams';

import { ReactionWithList } from './ReactionWithList';

export const ReactionEducation: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const pathData = useAlternativeParams('ghost');

  const ghostApplication = useSelector(
    (state: AppState) => state.applicationFlow.ghostApplication,
  );

  const onDelete = async (id: string) => {
    const idx = ghostApplication.educations.findIndex((exp) => exp.id === id);
    ghostApplication.educations.splice(idx, 1);

    dispatch(
      applicationFlowActions.updateGhostApplication({
        ghostApplication: { ...ghostApplication },
      }),
    );

    if (ghostApplication.educations.length === 0) {
      dispatch(
        applicationFlowActions.markEducation({
          isEducationDone: false,
        }),
      );
    }
  };

  return (
    <ReactionWithList
      nextDisabled={Boolean(ghostApplication.educations.length === 0)}
      skippable={false}
      header={
        <Heading as="h1" size="fluidLarge">
          <FormattedMessage id="onboarding.dynamic_headings.education_list" />
        </Heading>
      }
      navigateTo={`/opening/ghost/${pathData.company}/${pathData.slug}/languages`}
      currentData={ghostApplication.educations.map((g) => {
        return {
          title: g.educationalInstitution,
          id: g.id,
          content: g.fieldOfStudy || '',
        };
      })}
      onDelete={onDelete}
    />
  );
};
