interface LocationState {
  isPhoneFix: boolean;
  key: string;
}

export function isPhoneFixInLocation(
  locationState: unknown | LocationState,
): locationState is LocationState {
  return (locationState as LocationState).isPhoneFix !== undefined;
}
