import { css } from '@emotion/react';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { borderRadius } from 'style/variables';

export const root = css`
  padding-bottom: calc(var(--unit) * 20);
  display: flex;

  @media ${breakpoints.large} {
    justify-content: center;
  }

  label {
    display: block;
    text-align: center;
    margin: 0 auto;

    color: hsl(var(--color-grayscale-1));

    width: 50px;
    height: 50px;

    padding-top: calc(var(--unit) * 3 + 1px);
    border: 1px solid hsl(var(--color-grayscale-1));
    border-radius: ${borderRadius.full};

    @media ${breakpoints.medium} {
      width: 52px;
      height: 52px;
    }
  }

  p {
    margin-top: calc(var(--unit) * 3);
    text-align: center;
  }
`;

export const passed = css`
  label {
    background-color: hsl(var(--color-textSuccess-7));
    border: none;
  }

  p {
    color: hsl(var(--color-textSuccess-7));
  }
`;

export const dottedSeparator = css`
  margin-top: calc(var(--unit) * 5 + 3px);
  border-top: 1px dashed hsl(var(--color-grayscale-1));
  flex-grow: 1;
  max-width: 295px;
  min-width: 16px;
`;

export const wrapper = css`
  overflow-x: auto;
`;
