import React, { useEffect, useState } from 'react';
import { grid } from '@prototyp/gatsby-plugin-gumball/utils';
import { Heading, LabelTag, Paragraph, ToggleSwitchInput } from 'modules/app';
import { useForm, FormProvider } from 'react-hook-form';
import { AppState } from 'modules/redux-store';
import { useDispatch, useSelector } from 'react-redux';

import * as styles from 'modules/application/styles/StealthInfo.styles';
import * as infoStyles from 'modules/application/styles/Info.styles';

import { FormattedMessage } from 'react-intl';
import { applicationFlowActions } from '../redux';
import { ContentWrapper } from './ContentWrapper';

export const Stealth: React.FC = () => {
  const { user } = useSelector((state: AppState) => state.session);
  const [privacyMode, setPrivacyMode] = useState<boolean>(
    user?.privacyMode === 'private' ? true : false,
  );
  const methods = useForm({
    defaultValues: {
      stealth: privacyMode,
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      applicationFlowActions.addApplicationFlowData({
        privacyMode: privacyMode,
      }),
    );
  }, [privacyMode, dispatch]);

  return (
    <ContentWrapper customStyles={[styles.root, infoStyles.overviewStealth]}>
      <Heading as="h2" size="xxLarge" css={[grid.colSpan.c6]}>
        <FormattedMessage id="publicApplication.stealth.setPrivacyHeading" />
      </Heading>

      <div css={[grid.colSpan.c5, grid.colStart.c8]}>
        <Paragraph size="fluidBase" fontFamily="primary">
          <FormattedMessage id="publicApplication.stealth.privacyDescription" />
        </Paragraph>

        <FormProvider {...methods}>
          <form css={styles.stealthModeForm}>
            <LabelTag size="base" fontFamily="primary">
              <FormattedMessage id="publicApplication.stealth.privacyLabel" />
            </LabelTag>

            <ToggleSwitchInput
              name="stealth"
              onChecked={(checked) => setPrivacyMode(checked)}
              checked={privacyMode}
              constraints={{
                required: false,
              }}
            />
          </form>
        </FormProvider>
      </div>
    </ContentWrapper>
  );
};
