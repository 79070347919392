import React, { useState, useEffect, useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  SelectComponent,
  Button,
  HttpError,
  Loading,
  useTransformSelect,
  TalentSecondLanguage,
  Value,
  proficiencyValues,
} from 'modules/app';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { motion, AnimatePresence } from 'framer-motion';
import { FADE_IN } from 'style/animations.config';

import { useReset } from 'modules/onboarding';
import * as styles from 'modules/app/styles/GenericForm.styles';
import { applicationFlowActions } from 'modules/application';
import { useTalentLanguage } from 'modules/talents';

interface Props {
  resetFormCounter?: () => void;
}

function transformLanguage(
  value: string | undefined | null,
  selectValues: Value[],
): Value | undefined {
  const valueLabel = selectValues.filter((val) => {
    return val.value === value;
  });
  if (value && selectValues && valueLabel.length !== 0) {
    return {
      value: value,
      label: valueLabel[0].label,
    };
  }
  return undefined;
}

export const TalentLanguagesForm: React.FC<Props> = ({ resetFormCounter }) => {
  const ghostApplication = useSelector(
    (state: AppState) => state.applicationFlow.ghostApplication,
  );
  const dispatch = useDispatch();

  const methods = useForm<LanguageRequest>({
    defaultValues: {
      language: '',
      proficiency: '',
      understandingListening: null,
      understandingReading: null,
      spokenProduction: null,
      spokenInteraction: null,
      writing: null,
    },
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    setError,
    control,
  } = methods;
  const { httpError, getLanguageConfig } = useTalentLanguage();
  const [selectedProficiency, setSelectedProficiency] = useState<
    string | string[]
  >('');
  const [isSecondLanguage, setIsSecondLanguage] = useState(false);
  const [languageValues, setLanguageValues] = useState<Value[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const formRef = useRef<HTMLFormElement>(null);
  const { resetValue, setReset } = useReset();
  const proficiency = useTransformSelect(undefined, proficiencyValues);

  const language = transformLanguage(undefined, languageValues);

  const resetForm = () => {
    reset();
    setReset();
    setIsSecondLanguage(false);
  };

  const getFormData = (data: LanguageRequest): GhostLanguage => {
    return {
      id: Date.now() + Math.random() + '',
      language: data.language,
      proficiency: data.proficiency,
      understandingListening: data.understandingListening,
      understandingReading: data.understandingReading,
      spokenProduction: data.spokenProduction,
      spokenInteraction: data.spokenInteraction,
      writing: data.writing,
    };
  };

  const onSubmit = async (data: LanguageRequest) => {
    const model = getFormData(data);

    const foundLanguage = ghostApplication.languages.find(
      (language) => language.language === model.language,
    );
    const natives = ghostApplication.languages.filter(
      (language) => language.proficiency === 'native',
    );

    if (foundLanguage) {
      setError('language', {
        type: 'manual',
        message: 'http_errors.item_already_exists',
      });

      return;
    }

    if (natives.length >= 3 && model.proficiency === 'native') {
      setError('language', {
        type: 'manual',
        message: 'http_errors.max_native_languages',
      });

      return;
    }

    ghostApplication.languages = [...ghostApplication.languages, model];
    dispatch(
      applicationFlowActions.updateGhostApplication({
        ghostApplication: { ...ghostApplication },
      }),
    );

    dispatch(
      applicationFlowActions.markLanguages({
        areLanguagesDone: true,
      }),
    );

    resetForm();
    resetFormCounter?.();
  };

  useEffect(() => {
    if (!Boolean(Object.keys(errors).length)) return;

    formRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [errors]);

  useEffect(() => {
    setIsSecondLanguage(false);

    const timeout = setTimeout(() => {
      if (!selectedProficiency) return;
      selectedProficiency === 'native'
        ? setIsSecondLanguage(false)
        : setIsSecondLanguage(true);
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [selectedProficiency, setValue]);

  useEffect(() => {
    if (Boolean(languageValues.length)) return;

    getLanguageConfig().then((response) => {
      if (!response?.languages) return;
      setIsLoading(false);

      setLanguageValues(
        response.languages.map(
          (lang): Value => ({ value: lang.name, label: lang.name }),
        ),
      );
    });
  }, [languageValues]);

  return (
    <div css={styles.root}>
      <Loading
        altText="register.alt_tags.verification_loading"
        isLoading={isLoading}
        component={
          <>
            {Boolean(languageValues.length) && !httpError && !isLoading && (
              <>
                <FormProvider
                  {...{
                    ...methods,
                    handleSubmit,
                    reset,
                    setValue,
                    errors,
                    setError,
                    control,
                  }}
                >
                  <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
                    <div css={styles.subgridForm}>
                      <SelectComponent
                        name="language"
                        label={
                          <FormattedMessage id="onboarding.form.fields.languages.language" />
                        }
                        constraints={{ required: true }}
                        reset={resetValue}
                        values={languageValues}
                        selectedValue={language}
                      />

                      <SelectComponent
                        setSelectedValue={(value) => {
                          setSelectedProficiency(value);
                        }}
                        name="proficiency"
                        label={
                          <FormattedMessage id="onboarding.form.fields.languages.proficiency" />
                        }
                        constraints={{ required: true }}
                        reset={resetValue}
                        selectedValue={proficiency}
                        values={proficiencyValues}
                      />
                    </div>
                    <AnimatePresence exitBeforeEnter>
                      {isSecondLanguage && (
                        <motion.div
                          transition={{ duration: 0.1 }}
                          {...FADE_IN}
                          exit={{ transition: { duration: 0.1 } }}
                        >
                          <TalentSecondLanguage
                            selected={selectedProficiency}
                          />
                        </motion.div>
                      )}
                    </AnimatePresence>

                    <Button type="submit" css={styles.submitBtn}>
                      <FormattedMessage id="onboarding.form.fields.languages.save_language" />
                    </Button>
                  </form>
                </FormProvider>
              </>
            )}
            {!isLoading && httpError && <HttpError error={httpError} />}
          </>
        }
      />
    </div>
  );
};
