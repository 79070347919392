import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  padding: ${spacing(60)} 0;
  grid-column: span 12;
  display: flex;
  flex-direction: column;

  @media ${breakpoints.smallMax} {
    button {
      width: 100%;
    }
  }

  button {
    @media ${breakpoints.smallMax} {
      max-width: 320px;
      margin-bottom: ${spacing(20)};
    }
  }

  > button {
    @media ${breakpoints.smallMax} {
      margin-bottom: ${spacing(64)};
    }
  }

  @media ${breakpoints.medium} {
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    div:last-of-type {
      justify-self: right;
    }
  }
`;

export const errorWrapper = css`
  grid-column: span 12;
  display: flex;
  flex-direction: column;
  margin-top: -40px;

  & > a {
    align-self: flex-end;
  }
`;

export const validationError = css`
  margin-bottom: calc(var(--unit) * 6);
`;

export const wrapper = css`
  display: flex;

  @media ${breakpoints.smallMax} {
    flex-direction: column;
  }

  @media ${breakpoints.medium} {
    margin-left: auto;
  }
`;

export const checkboxForm = css`
  margin-top: ${spacing(20)};

  div:first-of-type {
    margin-bottom: ${spacing(20)};
  }

  a {
    color: hsl(var(--color-text-1));
    font-weight: var(--font-weight-bold);
    text-decoration: underline;
  }

  @media ${breakpoints.large} {
    div:first-of-type {
      margin-bottom: ${spacing(40)};
    }
  }
`;
