import React from 'react';
import { Router } from '@reach/router';
import { PageProps } from 'gatsby';
import Seo from 'modules/app/components/Seo';
import { Root as ApplicationRoot } from 'modules/application/Root';
import { Root as GhostRoot } from 'modules/ghostApplication/Root';
import { FinalScreen } from 'modules/application/application/FinalScreen';
import { ConfirmApplication } from 'modules/ghostApplication/components/ConfirmApplication';
import { NotFound } from 'modules/notFound';
import { useIntl } from 'gatsby-plugin-intl';
import { Preview } from 'modules/publicOpening/Preview';
import { ProtectedRouter } from 'modules/app';

const Opening: React.FC<PageProps> = () => {
  const { formatMessage } = useIntl();

  if (typeof window === 'undefined') return null;

  return (
    <>
      <Seo title={formatMessage({ id: 'seoTitle.openingOverview' })} />

      <Router basepath="/:lang/opening">
        <GhostRoot path="ghost/*" />

        <FinalScreen path="finish/:companyHandle" />

        <ApplicationRoot path="application/:companyHandle/:openingSlug/*" />

        <ConfirmApplication path="confirm/:token" />

        <ProtectedRouter path="/:companyId/:openingId" Component={Preview} />

        <NotFound default type="dashboardFull" />
      </Router>
    </>
  );
};

export default Opening;
