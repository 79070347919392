import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import React from 'react';
import { Pill } from './Pill';
import * as styles from 'modules/application/styles/Apply.styles';

interface Props {
  error: ApiErrorData | undefined;
  customStyles?: SerializedStyles | SerializedStyles[];
  openingSlug?: string;
  companyName?: string;
  isGhostApply?: boolean;
}

export const ValidationError: React.FC<Props> = ({
  error,
  customStyles,
  openingSlug,
  companyName,
  isGhostApply,
}) => {
  if (error && error.status === 422 && error.errors) {
    const errors = error.errors;

    if (errors['phone']) {
      return (
        <>
          <Pill
            css={customStyles}
            type="error"
            title="shared.validationErrors.title"
            text="shared.validationErrors.phoneErrorText"
          />
          {isGhostApply && (
            <Link
              to={`/opening/ghost/${companyName}/${openingSlug}/basics`}
              css={styles.fixBtn}
              state={{ isPhoneFix: true }}
            >
              <FormattedMessage id="shared.validationErrors.ghost.phone" />
            </Link>
          )}
        </>
      );
    }
    if (errors['coverLetter']) {
      return (
        <>
          <Pill
            css={customStyles}
            type="error"
            title="shared.validationErrors.title"
            text="shared.validationErrors.coverLetter"
          />
          <Link
            to={`/opening/application/${companyName}/${openingSlug}/cover`}
            css={styles.fixBtn}
          >
            <FormattedMessage id="shared.validationErrors.ghost.phone" />
          </Link>
        </>
      );
    }
  }

  return null;
};
