import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  border-bottom: 1px solid hsl(var(--color-backgroundAlt-9));

  p {
    color: hsl(var(--color-borderDark-5));
  }
`;

export const wrapper = css`
  padding-top: ${spacing(120)};
  padding-bottom: ${spacing(80)};

  @media ${breakpoints.large} {
    display: flex;
    justify-content: space-between;
  }
`;

export const column = css`
  > p {
    color: hsl(var(--color-grayscale-6));
  }

  @media ${breakpoints.large} {
    max-width: 430px;
  }
`;

export const noBorder = css`
  border: none;
`;

export const stealthModeForm = css`
  display: flex;
  justify-content: left;

  margin-top: calc(var(--unit) * 7);

  label {
    color: hsl(var(--color-text-1));
    padding-top: calc(var(--unit) * 2);

    margin-right: calc(var(--unit) * 6 + 1px);
  }
`;
