import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { HttpError } from 'modules/app';
import { debounce } from 'throttle-debounce';
import { FormattedMessage } from 'react-intl';
import { FormError } from 'modules/app/intl';
import * as styles from 'modules/app/styles/ExpertiseForm.styles';
import { removeWhiteSpace } from 'modules/talents';
import { useAssets } from 'modules/shared';

type Props = {
  setChosenExpertise: (tag: string) => void;
  totalExpertise: number;
  setTotalExpertise: React.Dispatch<React.SetStateAction<number>>;
};

export const TalentExpertiseForm: React.FC<Props> = ({
  setChosenExpertise,
  totalExpertise,
  setTotalExpertise,
}) => {
  const methods = useForm<{ expertise: string }>({
    defaultValues: {
      expertise: '',
    },
  });
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    setError,
  } = methods;
  const [formError, setFormError] = useState<ApiErrorData>();

  const { suggestedTags, setSuggestedTags, getAllTags } = useAssets();

  const addUserExpertise = (data: { expertise: string }) => {
    if (totalExpertise < 10) {
      setFormError(undefined);
      reset();
      setSuggestedTags(undefined);

      setTotalExpertise(totalExpertise);
      setChosenExpertise(removeWhiteSpace(data.expertise));
      return;
    }
    reset();
    setSuggestedTags(undefined);
    setError('expertise', {
      type: 'manual',
      message: 'http_errors.max_expertise',
    });
  };

  const onInputChange = debounce(300, (text: string) => {
    getAllTags(text);
  });

  return (
    <div css={styles.expertise}>
      <FormProvider {...methods}>
        <form
          css={
            errors.expertise ? styles.expertiseFormError : styles.expertiseForm
          }
          onSubmit={handleSubmit(addUserExpertise)}
        >
          <div css={styles.expertiseFormHash}>#</div>
          <label
            htmlFor="expertise"
            css={errors.expertise ? styles.labelError : styles.label}
          >
            <FormattedMessage id="onboarding.form.fields.expertise.choose_a_tag" />
          </label>
          <input
            css={styles.expertiseFormInput}
            type="text"
            placeholder="tag"
            {...register('expertise', {
              required: true,
              maxLength: {
                value: 30,
                message: 'shared.errors.max',
              },
              onChange: (e) => onInputChange(e.target.value),
            })}
            autoComplete="off"
          />
          <input css={styles.expertiseFormSubmit} type="submit" value="Add" />
        </form>

        <FormError
          errors={errors}
          name="expertise"
          values={{
            max: 30,
          }}
        />
      </FormProvider>
      {suggestedTags && (
        <div css={styles.suggested}>
          {suggestedTags?.map((tag) => (
            <div css={styles.suggestedItem} key={tag.id}>
              <p
                onClick={() => {
                  addUserExpertise({ expertise: tag.name });
                }}
                css={styles.suggestedText}
              >
                {tag.name}
              </p>
              <p css={styles.suggestedResults}>
                {tag.totalCount.toString()} results
              </p>
            </div>
          ))}
        </div>
      )}

      {formError && <HttpError error={formError} />}
    </div>
  );
};
