import React, { useEffect, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, ValidationError, TalentProjectInputs } from 'modules/app';
import { FormattedMessage } from 'react-intl';
import * as styles from 'modules/app/styles/ProjectsForm.styles';

import { useReset } from 'modules/onboarding';
import { applicationFlowActions } from 'modules/application';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';

interface Props {
  resetFormCounter?: () => void;
  experienceData: GhostExperience[];
}

export const TalentProjectsForm: React.FC<Props> = ({
  resetFormCounter,
  experienceData,
}) => {
  const ghostApplication = useSelector(
    (state: AppState) => state.applicationFlow.ghostApplication,
  );
  const dispatch = useDispatch();

  const methods = useForm<ProjectsFormData>({
    defaultValues: {
      name: '',
      owner: '',
      startDate: null,
      endDate: null,
      relatedWith: null,
      userExperienceId: null,
      url: null,
      description: null,
      featured: false,
    },
  });
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = methods;
  const [validationError, setValidationError] = useState<ApiErrorData>();
  const { resetValue, setReset } = useReset();
  const positionalRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (Boolean(Object.keys(errors).length)) {
      positionalRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errors]);

  const getFormData = (data: ProjectsFormData): GhostProject => {
    return {
      id: Date.now() + Math.random() + '',
      ...data,
      url: data.url || null,
      startDate: data.startDate
        ? `${data.startDate.getFullYear()}-${
            data.startDate.getMonth() + 1
          }-${data.startDate.getDate()}`
        : '',
      endDate: data.endDate
        ? `${data.endDate.getFullYear()}-${
            data.endDate.getMonth() + 1
          }-${data.endDate.getDate()}`
        : null,
      relatedWith: data.relatedWith || null,
      userExperienceId: data.userExperienceId || null,
      featured: data.featured,
      description: data.description ? data.description : null,
    };
  };

  const onValid = async (data: ProjectsFormData) => {
    setValidationError(undefined);
    const model = getFormData(data);

    setValidationError(undefined);

    ghostApplication.projects = [...ghostApplication.projects, model];
    dispatch(
      applicationFlowActions.updateGhostApplication({
        ghostApplication: { ...ghostApplication },
      }),
    );

    reset();
    setReset();
    setValue('relatedWith', null);
    setValue('userExperienceId', null);

    resetFormCounter?.();
  };

  return (
    <div css={styles.root}>
      <FormProvider {...methods}>
        <form ref={positionalRef} onSubmit={handleSubmit(onValid)}>
          <ValidationError
            styles={styles.httpErrorFix}
            error={validationError}
          />

          <TalentProjectInputs
            resetValue={resetValue}
            experienceData={experienceData as Experience[]}
          />

          <Button type="submit">
            <FormattedMessage id="onboarding.form.fields.project_list.save_projects_button_label" />
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};
