import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import {
  GooglePlacesAutocomplete,
  Heading,
  InputField,
  SelectComponent,
  Root as MemoryUpload,
  TextArea,
  parseDate,
  parseNullableOrEmpty,
  EmailField,
  useCompanyDescriptionValues,
  useTalentBasicValues,
} from 'modules/app';
import { FormProvider, useForm } from 'react-hook-form';

import * as styles from 'modules/onboarding/styles/Basics.styles';
import * as basicsStyles from 'modules/application/styles/Basics.styles';
import { grid } from '@prototyp/gatsby-plugin-gumball/utils';
import { applicationFlowActions } from 'modules/application/redux';
import { WorkplaceEnum } from 'modules/profilePreview';
import { useIntl } from 'gatsby-plugin-intl';
import { useLocation } from '@reach/router';
import { isPhoneFixInLocation } from '../typeguards';

const DateOfBirth = React.lazy(
  () => import('modules/app/components/DateOfBirth'),
);

interface Props {
  setRequestSuccess: () => void;
  onInit: (form: HTMLFormElement) => void;
  onInRequest: (isInRequest: boolean) => void;
  formData?: Basics;
}

interface FormData {
  firstName: string;
  lastName: string;
  cityCountry: string;
  place: SelectedPlace | undefined;
  email: string;
  title: string;
  phone: string | null;
  dob: Date;
  workplace: string[] | null;
  gender: string | null;
  disability: string | null;
  about: string | null;
}

function transformSelectedPlace(
  cityCountry: string | undefined | null,
  location?: GoogleLocation | undefined | null,
): SelectedPlace | undefined {
  if (location && cityCountry) {
    return {
      location: { x: location.x, y: location.y, srid: 0 },
      cityCountry: cityCountry,
    };
  }

  return undefined;
}

export const BasicsForm: React.FC<Props> = ({
  onInit,
  onInRequest,
  setRequestSuccess,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { formatMessage } = useIntl();
  const application = useSelector((state: AppState) => state.applicationFlow);
  const { typeOfWorkplace } = useCompanyDescriptionValues();
  const [selectedPlace, setSelectedPlace] = useState<SelectedPlace | undefined>(
    transformSelectedPlace(
      application.ghostApplication.cityCountry,
      application.ghostApplication.location,
    ),
  );
  const [profilePicture, setProfilePicture] = useState<string | null>(
    application.ghostApplication.profilePicture,
  );
  const { genderValues, disabilityValues } = useTalentBasicValues();

  const methods = useForm<FormData>({
    defaultValues: {
      firstName: application.ghostApplication?.firstName,
      lastName: application.ghostApplication?.lastName,
      email: application.ghostApplication?.email,
      title: application.ghostApplication?.title,
      workplace: application.ghostApplication?.workplace,
      phone: application.ghostApplication.phone,
      about: application.ghostApplication?.about,
      place: {
        cityCountry: '',
        location: {
          x: 0,
          y: 0,
        },
      },
      dob: application.ghostApplication?.dob
        ? new Date(application.ghostApplication.dob)
        : undefined,
      gender: application.ghostApplication?.gender || null,
      disability: application.ghostApplication?.disability || null,
    },
  });
  const {
    handleSubmit,
    setError,
    formState: { errors },
    setValue,
  } = methods;
  const isWrongPhoneFormat = Boolean(errors.phone);

  const formRef = useRef<HTMLFormElement>(null);

  const onValid = async (data: FormData) => {
    onInRequest(true);
    const ghostApplication: GhostApplication = {
      ...application.ghostApplication,
      ...{
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        title: data.title,
        workplace: data.workplace,
        phone: data.phone || null,
        about: data.about,
        dob: parseDate(data.dob, false) || '',
        gender: parseNullableOrEmpty(data.gender),
        disability: parseNullableOrEmpty(data.disability),
        profilePicture: profilePicture,
        cityCountry: selectedPlace?.cityCountry || '',
        location: selectedPlace?.location || { x: 0, y: 0, srid: 0 },
      },
    };

    dispatch(
      applicationFlowActions.markBasics({
        areBasicsDone: true,
      }),
    );

    dispatch(
      applicationFlowActions.updateGhostApplication({
        ghostApplication: ghostApplication,
      }),
    );

    setRequestSuccess();

    onInRequest(false);
  };

  useEffect(() => {
    if (formRef.current) {
      onInit(formRef.current);
    }
  }, [formRef, onInit]);

  useEffect(() => {
    if (!location.state || !isPhoneFixInLocation(location.state)) return;

    setError?.('phone', {
      type: 'invalidPhone',
      message: formatMessage({
        id: 'onboarding.form.errors.phone',
      }),
    });
  }, [location.state]);

  return (
    <FormProvider {...{ ...methods, handleSubmit, setError, errors, setValue }}>
      <form onSubmit={handleSubmit(onValid)} ref={formRef} css={styles.form}>
        <div
          css={[
            grid.display,
            grid.template.cols12,
            grid.colSpan.c12,
            grid.gap.lrg,
            basicsStyles.gridFix,
          ]}
        >
          <EmailField
            name="email"
            value={application.ghostApplication.email}
            constraints={{
              required: true,
            }}
          />

          <InputField
            customStyles={grid.colSpan.c6}
            name="firstName"
            label="register.name_label"
            value={application.ghostApplication.firstName}
            constraints={{
              maxLength: 150,
              required: true,
            }}
          />

          <InputField
            customStyles={grid.colSpan.c6}
            name="lastName"
            value={application.ghostApplication.lastName}
            label="register.last_name_label"
            constraints={{
              maxLength: 150,
              required: true,
            }}
          />
        </div>

        <div css={styles.twoGridFlexbox}>
          <div css={styles.profileUploadWrapper}>
            <MemoryUpload
              label="profileManagement.basicInfo.profileImageLabel"
              inputName="profilePicture"
              minAspectRatio={1}
              maxAspectRatio={1}
              onImageUploaded={(file) => {
                setProfilePicture(file);
              }}
              existingImage={profilePicture}
              optional={true}
            />
          </div>

          <div>
            <GooglePlacesAutocomplete
              name="place"
              customStyles={styles.googlePlacesFix}
              id="place"
              selectedPlace={selectedPlace}
              onSelectedPlace={(place: SelectedPlace | undefined) =>
                setSelectedPlace(place)
              }
              label="onboarding.form.fields.basics.location"
            />

            <SelectComponent
              isOptional={true}
              name="workplace"
              isMulti={true}
              label={
                <FormattedMessage id="onboarding.form.fields.basics.workplace" />
              }
              selectedValue={
                !application.ghostApplication.workplace
                  ? []
                  : application.ghostApplication.workplace.map((workplace) => {
                      return {
                        value: workplace,
                        label: (
                          <FormattedMessage id={WorkplaceEnum[workplace]} />
                        ),
                      };
                    })
              }
              values={typeOfWorkplace}
              constraints={{
                validate: (values: string[]) => {
                  // workplace is optional, if empty, it is valid
                  if (!Boolean(values.length)) {
                    return true;
                  }

                  return !Boolean(values.length === 0);
                },
              }}
            />

            <InputField
              name="phone"
              label="onboarding.form.fields.basics.phone"
              customStyles={isWrongPhoneFormat ? styles.phoneError : undefined}
              constraints={{
                maxLength: 50,
                minLength: {
                  value: 7,
                  message: formatMessage({
                    id: 'onboarding.form.fields.basics.phone.min_length',
                  }),
                },
                pattern: {
                  value: /^\+[1-9]{1}\d{3,20}$/g,
                  message: formatMessage({
                    id: 'onboarding.form.fields.basics.phone.error',
                  }),
                },
              }}
              value={application.ghostApplication?.phone || ''}
              isOptional
            />

            <SelectComponent
              isOptional={true}
              name="gender"
              label={
                <FormattedMessage id="onboarding.form.fields.basics.gender" />
              }
              selectedValue={
                application.ghostApplication.gender
                  ? genderValues.find(
                      (value) =>
                        value.value === application.ghostApplication.gender,
                    )
                  : undefined
              }
              values={genderValues}
              constraints={{}}
            />

            <React.Suspense fallback={null}>
              <DateOfBirth
                name="dob"
                value={application.ghostApplication.dob}
                constraints={{
                  required: true,
                  validate: {
                    validateDateRange: (date: Date) => {
                      if (date.getFullYear() + 18 <= new Date().getFullYear()) {
                        return true;
                      }

                      return 'onboarding.form.errors.dob';
                    },
                  },
                }}
              />
            </React.Suspense>

            <SelectComponent
              isOptional={true}
              name="disability"
              isMulti={false}
              label={
                <FormattedMessage id="onboarding.form.fields.basics.disability" />
              }
              selectedValue={
                application.ghostApplication.disability
                  ? disabilityValues.find(
                      (value) =>
                        value.value === application.ghostApplication.disability,
                    )
                  : undefined
              }
              values={disabilityValues}
              constraints={{}}
            />
          </div>
        </div>

        <div css={styles.subheaderGroup}>
          <Heading as="h1" size="fluidLarge">
            <FormattedMessage id="onboarding.headings.basics.2" />
          </Heading>
          <Heading as="h1" size="fluidLarge">
            <FormattedMessage id="onboarding.headings.basics.3" />
          </Heading>
        </div>

        <InputField
          customStyles={styles.basicsFirstField}
          name="title"
          label="onboarding.form.fields.basics.title_label"
          value={application.ghostApplication.title}
          constraints={{
            maxLength: 150,
            required: true,
          }}
        />

        <TextArea
          isOptional={true}
          constraints={{
            maxLength: {
              value: 200,
              message: 'shared.errors.max',
            },
          }}
          name="about"
          placeholder="onboarding.form.fields.basics.about_placeholder"
          topLabel="onboarding.form.fields.basics.about_label"
          value={application.ghostApplication.about || ''}
        />
      </form>
    </FormProvider>
  );
};
