import React from 'react';
import { TextEditor } from 'modules/app';

import * as styles from 'modules/application/styles/CoverLetter.styles';

interface Props {
  coverLetter: string | undefined;
}

export const CoverLetter: React.FC<Props> = ({ coverLetter }) => {
  return (
    <div css={styles.root}>
      <TextEditor
        defaultValue={coverLetter ? JSON.parse(coverLetter) : undefined}
        label="publicApplication.coverLetterLabel"
        name="coverLetter"
        placeholder="publicApplication.coverLetterPlaceholder"
        constraints={{
          required: false,
          maxLength: {
            value: 5000,
            message: 'shared.errors.max',
          },
        }}
        maxChar={5000}
      />
    </div>
  );
};
