import React from 'react';
import { Heading, Paragraph } from 'modules/app';

import * as styles from 'modules/application/styles/StealthInfo.styles';

import { FormattedMessage } from 'react-intl';
import { padding } from '@prototyp/gatsby-plugin-gumball/utils';

export const CoverDescription: React.FC = () => {
  return (
    <div css={styles.wrapper}>
      <Heading
        css={[styles.column, padding.bottom.xxlrg]}
        as="h2"
        size="xxLarge"
      >
        <FormattedMessage id="publicApplication.coverLetterSection.heading" />
      </Heading>

      <div css={styles.column}>
        <Paragraph size="fluidBase" fontFamily="primary">
          <FormattedMessage id="publicApplication.coverLetterSection.description" />
        </Paragraph>
      </div>
    </div>
  );
};
