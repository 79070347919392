import React from 'react';
import { Button, Logo } from 'modules/app';
import { ListItem } from './ListItem';
import { navigate } from 'gatsby-plugin-intl';
import { FormattedMessage } from 'gatsby-plugin-intl';

import * as styles from 'modules/onboarding/styles/ReactionRoot.styles';

interface Props {
  header: React.ReactNode;
  navigateTo: string;
  currentData: ListingData[];
  onDelete: (id: string) => void;
  onEdit?: (id: string) => void;
  isLastStep?: boolean;
  skippable?: boolean;
  nextDisabled?: boolean;
}

export const ReactionWithList: React.FC<Props> = ({
  header,
  navigateTo,
  currentData,
  onDelete,
  onEdit,
  isLastStep,
  skippable = true,
  nextDisabled = false,
}) => {
  return (
    <div css={styles.root}>
      <div>
        <Logo />

        <div css={styles.headerGroup}>{header}</div>

        {Boolean(currentData.length) && (
          <ul css={styles.listGroup}>
            {currentData.map((data: ListingData) => (
              <ListItem
                key={data.id}
                title={data.title}
                content={data.content}
                isFeatured={data.featured}
                isHeadquarters={data.headquarters}
                id={data.id}
                onDelete={onDelete}
                onEdit={onEdit}
              />
            ))}
          </ul>
        )}
      </div>

      <div css={styles.buttonGroupGhost}>
        <Button
          buttonType="secondary"
          disabled={nextDisabled}
          onClick={() => navigate(navigateTo)}
        >
          <FormattedMessage
            id={
              isLastStep
                ? 'onboarding.form.finish_button'
                : 'onboarding.form.next_step_up'
            }
          />
        </Button>

        {!isLastStep && skippable && (
          <Button
            css={styles.skipForNow}
            buttonType="ghost"
            onClick={() => {
              navigate(navigateTo);
            }}
          >
            <FormattedMessage id="onboarding.form.skip_for_now" />
          </Button>
        )}
      </div>
    </div>
  );
};
