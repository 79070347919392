import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { grid } from '@prototyp/gatsby-plugin-gumball/utils';
import { FormattedMessage } from 'react-intl';

import {
  CoverLetter,
  HeaderLayout,
  HttpError,
  Loading,
  MainContent,
  usePreviewContext,
  Heading,
  Paragraph,
  useCsrfCookie,
} from 'modules/app';
import { TalentPreviewBox } from 'modules/app';
import {
  Breadcrumbs,
  ApplicationHeading,
  ContentWrapper,
  InfoComponent,
  Preview,
  GhostBreadcrumbs,
} from 'modules/application/components';

import * as styles from 'modules/application/styles/Apply.styles';
import * as infoStyles from 'modules/application/styles/Info.styles';
import * as previewStyles from 'modules/application/styles/Preview.styles';
import { SubmitForm } from '../components/SubmitForm';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { motion } from 'framer-motion';
import { useTalentPreview } from 'modules/talents';
import { useApplication } from '../hooks';

interface Props extends RouteComponentProps {
  publicOpening: PublicOpening;
}

export const ApplyRoot: React.FC<Props> = ({ publicOpening }) => {
  const { isLoggedIn } = useCsrfCookie();
  const coverLetter = useSelector(
    (state: AppState) => state.applicationFlow.coverLetter,
  );
  const privacyMode = useSelector(
    (state: AppState) => state.applicationFlow.privacyMode,
  );

  const { Provider } = usePreviewContext();
  const { httpError, previewData, getApplicationPreview } = useTalentPreview();

  const { error, isLoading, userApply } = useApplication();

  function onSubmit(data: {
    isTosAccepted: boolean;
    isPrivacyPolicyAccepted: boolean | undefined;
  }) {
    userApply(publicOpening.companyHandle, publicOpening.slug, data);
  }

  useEffect(() => {
    getApplicationPreview();
  }, []);

  return (
    <motion.div {...FADE_IN_MOVE_Y_REGULAR}>
      <HeaderLayout>
        <ApplicationHeading
          title={publicOpening.title}
          companyName={publicOpening.company}
          companyPicture={publicOpening.companyIconPicture}
        />
        {isLoggedIn() && <Breadcrumbs state={['cover', 'apply']} />}
        {!isLoggedIn() && <GhostBreadcrumbs state={['cover', 'apply']} />}
      </HeaderLayout>

      <MainContent>
        <ContentWrapper>
          {error && (
            <div css={styles.error}>
              <HttpError error={error} />
            </div>
          )}
          <Heading
            css={[grid.colSpan.c12, styles.heading]}
            as="h1"
            size="fluidXxLarge"
          >
            <FormattedMessage id="publicApplication.apply.reviewHeader" />
          </Heading>
        </ContentWrapper>

        <InfoComponent
          customStyles={infoStyles.appSummary}
          heading={
            <Heading as="h2" size="fluidXLarge" css={[grid.colSpan.c6]}>
              <FormattedMessage id="publicApplication.apply.stepOneHeading" />
            </Heading>
          }
          paragraph={
            <Paragraph size="fluidBase" fontFamily="primary">
              <FormattedMessage id="publicApplication.apply.stepOneDesc" />
            </Paragraph>
          }
        />

        <Preview
          isInStealthMode={privacyMode}
          customStyles={previewStyles.applySpacing}
          companyHandle={publicOpening.companyHandle}
        />

        {coverLetter && (
          <>
            <InfoComponent
              customStyles={infoStyles.appSummary}
              heading={
                <Heading as="h2" size="fluidXLarge" css={[grid.colSpan.c6]}>
                  <FormattedMessage id="publicApplication.apply.stepTwoHeading" />
                </Heading>
              }
              paragraph={
                <Paragraph size="fluidBase" fontFamily="primary">
                  <FormattedMessage id="publicApplication.apply.stepTwoDesc" />
                </Paragraph>
              }
            />

            <ContentWrapper>
              <CoverLetter letter={coverLetter} />
            </ContentWrapper>
          </>
        )}

        <InfoComponent
          customStyles={infoStyles.cvInfo}
          heading={
            <Heading as="h2" size="fluidXLarge" css={[grid.colSpan.c6]}>
              <FormattedMessage id="publicApplication.apply.stepThreeHeading" />
            </Heading>
          }
          paragraph={
            <Paragraph size="fluidBase" fontFamily="primary">
              <FormattedMessage id="publicApplication.apply.stepThreeDesc" />
            </Paragraph>
          }
        />

        {privacyMode && (
          <ContentWrapper>
            <Paragraph
              css={[grid.colSpan.c12, infoStyles.stealthMode]}
              size="small"
              fontFamily="primary"
            >
              <FormattedMessage id="publicApplication.apply.stealthModeInfo" />
            </Paragraph>
          </ContentWrapper>
        )}

        <Loading
          isLoading={!previewData}
          component={
            <>
              {previewData && !httpError ? (
                <Provider
                  value={{
                    isPreview: true,
                    isGhost: false,
                  }}
                >
                  <div
                    css={[grid.display, grid.template.cols12, grid.colSpan.c12]}
                  >
                    <div css={styles.cvWrapper}>
                      <TalentPreviewBox
                        previewData={previewData}
                        isOpeningCvPreview
                      />
                    </div>
                  </div>
                </Provider>
              ) : (
                <HttpError error={httpError} />
              )}
            </>
          }
        />

        <ContentWrapper>
          <SubmitForm
            openingSlug={publicOpening.slug}
            companyHandle={publicOpening.companyHandle}
            isLoading={isLoading}
            onSubmit={onSubmit}
            submitError={error}
          />
        </ContentWrapper>
      </MainContent>
    </motion.div>
  );
};
