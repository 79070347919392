import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { useParams } from '@reach/router';
import { AppLayout, Loading, SSRError } from 'modules/app';
import { AppState } from 'modules/redux-store';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SSROpeningPreview } from './components';
import { useGetPublicOpening } from './hooks';

export const Preview: React.FC = () => {
  const { companyId, openingId } = useParams();
  const { getOpening } = useGetPublicOpening();
  const { isLoading, error, publicOpening } = useSelector(
    (state: AppState) => state.openings,
  );

  useEffect(() => {
    if (
      !companyId ||
      !openingId ||
      !(typeof companyId === 'string') ||
      !(typeof openingId === 'string')
    ) {
      return;
    }

    getOpening(companyId, openingId);
  }, [companyId, openingId]);

  return (
    <AppLayout>
      <Loading
        isLoading={isLoading}
        customStyles={margin.top.huge}
        component={
          <>
            {error && <SSRError error={error} />}
            {publicOpening && <SSROpeningPreview opening={publicOpening} />}
          </>
        }
      />
    </AppLayout>
  );
};
